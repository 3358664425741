import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';

const swaggerPath = `/b/admin/swagger`;

export interface SwaggerQueryOptions {
  router: string;
  server: string;
  file_type: string;
}

export const setupSwaggerEndpoints = (client: HttpClient) => {
  return {
    /** Returns a JSON object that can be passed to a Swagger UI config, e.g. the Swagger constructor's `spec` property */
    getApiDocsSpec: (
      query: Partial<SwaggerQueryOptions>,
      config?: ClientConfig
    ) => {
      if (!query?.router) {
        query.router = 'rest';
      }
      const queryString = query ? '?' + toURLParams(query) : '';
      // eslint-disable-next-line
      // @ts-ignore
      return client.get<{object}>(swaggerPath + queryString, config);
    },
  };
};
