import {CreateCheckoutParams} from '@stryd/models';
import {HttpClient, ClientConfig} from '../../http';

const checkoutPath = `/canyon/checkout`;

export const setupCheckoutEndpoints = (client: HttpClient) => {
  return {
    createSession: (params: CreateCheckoutParams, config?: ClientConfig) => {
      return client.post<{id: string}, string>(
        `${checkoutPath}/session`,
        params,
        config
      );
    },
  };
};
