import React from 'react';

import {
  IconArrowWaveRightUp,
  IconBoxAlignRightFilled,
  IconBoxAlignTopLeft,
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from '@tabler/icons-react';
import {motion} from 'framer-motion';
import {FormattedMessage} from 'react-intl';

import {IPowerMeterSectionFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

import {BentoGrid, BentoGridItem} from '@/components/ui/bento-grid';

type PowerMeterSectionProps =
  ContentfulTemplateComponentProps<IPowerMeterSectionFields>;

export const PowerMeterSection: React.FC<PowerMeterSectionProps> = (props) => {
  const {
    entry: {
      fields: {header = 'test', subheader = 'test', field = 'test'},
    },
  } = props;

  interface SkeletonProps {
    src: string; // `src` must be a string
  }

  const Skeleton: React.FC<SkeletonProps> = ({src}) => (
    <img
      src={src}
      alt="Testimonial"
      className="max-w-[50px] max-h-[50px] object-contain rounded-full"
    />
  );

  const items = [
    {
      title: 'Setting Personal Records Again',
      description: `
      Without being able to keep an eye on my average pace and by relying solely on my goal power zone, I did:
- a personal record on my half marathon by nearly a minute; my old PR was 4 years ago.
- 26'54" Parkrun: my second fastest to date and third fastest 5k overall. Again, my previous fastest times were 4-5 years ago.
All my best times had been achieved immediately before I got pregnant. After that I had to quit running due to physical reasons and I didn't resume running seriously until this past spring.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/736OfCNPZaJZARliS9YS1d/4890f13c1a107b36fef306e370e10bef/Paola.JPG" />
      ),
      icon: <IconClipboardCopy className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Confidence to Trust in Myself',
      description: `I couldn’t be happier. Stryd predicted I would finish in 1:29:40, which I thought was too ambitious. I managed to finish it in 1:29:55. I improved my PR by 8 minutes! 14K into the race, I thought that I would not be able to hold the pace. But I did — and I literally finished the run on the last bit of energy I had left. This really gave me the confidence to trust in myself and trust my Stryd. And, I might add, during the power-based training plan, I had a higher weekly volume than ever before, but I never felt overtrained or something. Really spot on. Kudos to this product.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/75WrQo2I33lMw72FrKnwAv/6f9a0fb79efa93a2fcef31a1aa6058bc/59503317_10156620095548525_6799908122719682560_n_1.png" />
      ),
      icon: <IconFileBroken className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Knowing how hard to push',
      description: `There are so many variables in trail ultramarathons. Knowing where I'm at & exactly how hard I can push, removes a big chunk of the guesswork & stress. Power helps me pace & train like no other metric can & my running continues to improve because of it.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/2ZNJkVldVfqMZesbLFys9s/f7be9f974ad9748a3910a5bda073bf75/Sally.JPG" />
      ),
      icon: <IconSignature className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Back to back PRs',
      description: `I am 4 for 4! 4 racing distance PRs using Stryd (5K, 10K, 13.1 and 26.2) in the last 2 months. BQed today and PRed by 10mins in my marathon. Followed my race watts consistently and had strong legs throughout. So happy I bought STRYD!`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/1Y8XSVDyPTuuWiszf6PtxS/ad9a302ad33db15635a753b9648889d3/Maria.JPG" />
      ),
      icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: '41 minute PR',
      description: `I just completed the 88km Comrades Ultra using Stryd. Stryd was absolutely critical to holding back in the first half of the race so I could conserve energy for the second half.

The result? 41 minutes faster than 2017. I set PRs on all the segments in the second half. Thanks, Stryd.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/52Lp1P1tvLRxgP4YIJvYJK/c915e09b7c5e8dea36937b05e8bd32be/64941772_10157421691004357_7062326174919163904_n_1.png" />
      ),
      icon: <IconArrowWaveRightUp className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: '4 weeks of training, really impressed',
      description: `Result of a 4-week Stryd HM training plan. Really impressed, especially as I was quite dubious at all the easy running.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/1icVZ6bp7q28txdH2J6j9z/5bf47678329228f8fa568926cb75fb25/180452035_490183282165200_7360494844919080724_n_1.png" />
      ),
      icon: <IconBoxAlignTopLeft className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Faster with more energy',
      description: `To my utter surprise, I did not only finish the 18K with tons of energy left (well...a good amount of energy), but I found out that my pace was more than 10s per km faster than I had achieved on the same route in the past. I honestly could not believe this! Not only was the route trickier than I usually would have chosen for an 18K run, but it turned out that I was much faster than usual yet I had more energy left! Running with power took all the mental effort out of my training as I did not have to gauge how fast to go uphill. I just ran, enjoyed the environment, stopped thinking, and I was faster. I am really liking this!`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/4g23WMH7ic8OCJYALeMshT/89ed3b229d1eb3add7f871bb92c6488f/Stef.JPG" />
      ),
      icon: <IconBoxAlignRightFilled className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Amazingly Accurate',
      description: `Sometimes I can’t believe how accurate Stryd can be. I went out today with the intention of a 10 mile moderate run by feel—not really paying attention to metrics, but was feeling strong early on and the weather was nice, so I pivoted to see if I could beat my half marathon PR since I’ve been training. Still continued to run mostly by feel until the last 2-3 miles or so where it was pretty much all out to maintain pace/output.. so overall a solid trial effort. Knocked 5 mins off my Half time. Now here’s the most interesting part.. avg’d 270W for a finish time of 1:39:11. Out of curiosity, I pulled up race calc in Stryd... suggested 269W with a finish time of 1:39:24. 13 seconds variance! It’s amazing how accurate Stryd is I absolutely love it.`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/47ceEQLr6fRlMvIrvAxukw/49dd407fa5e069be9882b80ee0708b0c/122256622_10106531862861374_5985044597443613253_n_1.png" />
      ),
      icon: <IconBoxAlignTopLeft className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: 'Ready in Seconds',
      description: `I’ve been doing the workouts on my Apple Watch and I love how easy it is to just open my Stryd app, sync my workout, and go!`,
      header: (
        <Skeleton src="https://images.ctfassets.net/8iip9pij3rta/6vOZCeE1Jnp4mSHx4lL8Aa/ae72a50a227c85a5e93c0fef927fe57a/178953492_10223160401825961_5106375195953372590_n_1.png" />
      ),
      icon: <IconBoxAlignRightFilled className="h-4 w-4 text-neutral-500" />,
    },
  ];

  const CTA = () => (
    <div className="relative h-[60vh] w-full overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-bottom z-0"
        style={{
          backgroundImage:
            'url(https://images.ctfassets.net/8iip9pij3rta/59x6SFozH9cWG0B0KnW5N5/05a517ddf751bd3bbe7d133cbb553056/tia-cta.png?h=1000)',
        }}
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-40 z-10" />

      {/* Content */}
      <div className="relative z-20 h-full flex flex-col justify-center items-center text-white px-4">
        <motion.h1
          className="text-5xl md:text-6xl font-bold mb-8 text-center"
          initial={{opacity: 0, y: -50}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.5}}
        >
          <FormattedMessage
            id="precision.startJourney"
            defaultMessage="Start your Stryd journey today"
          />
        </motion.h1>

        <div className="flex flex-col sm:flex-row gap-4">
          <motion.button
            className="px-6 py-3 bg-orange-600 text-white font-semibold rounded-full hover:bg-orange-500 transition duration-300"
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.95}}
          >
            <a href="/store">
              <FormattedMessage
                id="precision.buyStryd"
                defaultMessage="Buy Stryd"
              />
            </a>
          </motion.button>

          <motion.button
            className="px-6 py-3 bg-blue-600 text-white font-semibold hover:bg-blue-500 rounded-full transition duration-300"
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.95}}
          >
            <a href="https://blog.stryd.com/2024/10/01/major-update-precision-run-training/">
              <FormattedMessage
                id="precision.learnMore"
                defaultMessage="Learn more about Precision Training"
              />
            </a>
          </motion.button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="py-20">
        <motion.h1
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.2, duration: 0.5}}
          className="text-5xl md:text-6xl lg:text-7xl font-normal mb-8 text-center"
        >
          Join the <span className="font-bold">Stryd Community</span>
        </motion.h1>
        <BentoGrid className="max-w-7xl  pt-16 mx-8 lg:mx-auto">
          {items.map((item, i) => (
            <BentoGridItem
              key={i}
              title={item.title}
              description={item.description}
              header={item.header}
              className={i === 3 || i === 6 ? 'md:col-span-1' : 'md:col-span-1'}
            />
          ))}
        </BentoGrid>
      </div>
      <CTA />
    </>
  );
};
