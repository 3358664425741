import React from 'react';

import {motion, useScroll, useTransform} from 'framer-motion';
import {FormattedMessage} from 'react-intl';

export const ScrollBackground = ({
  image,
}: {
  image: string;
}): React.ReactElement => {
  const {scrollY} = useScroll();
  const backgroundY = useTransform(scrollY, [0, 1000], [0, 0]);
  const textY = useTransform(scrollY, [0, 1000], [0, -200]);

  return (
    <div className="relative h-[200vh] lg:h-[250vh] overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-cover bg-[20%] lg:bg-center"
        style={{
          backgroundImage: `url(${image})`,
          y: backgroundY,
        }}
      />

      <div className="relative z-10 h-full">
        <motion.div
          className="absolute top-1/4 right-1/4 transform translate-x-1/2 -translate-y-1/2 bg-black text-white p-8 max-w-3xl lg:max-w-2xl"
          style={{y: textY}}
        >
          <p className="text-orange-500 text-lg mb-2">
            <FormattedMessage
              id="scrollBackgroundOne.highlight"
              defaultMessage="Stryd goes with you"
            />
          </p>
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4 leading-tight">
            <FormattedMessage
              id="scrollBackgroundOne.lineOne"
              defaultMessage="Precision guidance"
            />
            <br />
            <FormattedMessage
              id="scrollBackgroundOne.lineTwo"
              defaultMessage="in every terrain"
            />
          </h1>
          <p className="text-gray-300 text-lg lg:text-md leading-relaxed">
            <FormattedMessage
              id="scrollBackgroundOne.supporting"
              defaultMessage="Stryd is your ultimate running companion for every terrain and
                    condition. From tracks to trails, indoor treadmills to outdoor runs,
                    you will have real-time power data to guide your training and
                    racing. With unparalleled accuracy and insights, you are always
                    ready to push your limits and achieve your personal best."
            />
          </p>
        </motion.div>
      </div>
    </div>
  );
};
