import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles';
import {
  getFontColor,
  getImageSourceFromAsset,
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageWidthFromAsset,
  getButtonColor,
} from 'src/utils/contentful';
import {IFeatureBreakdownFields} from 'src/utils/contentful/content-models/generated/contentful';

import {BigCenteredContent} from '../../BigCenteredContent';
import {CtaButton} from '../../CtaButton';
import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const {tabletPortrait, mobile} = dimensions.breakpoints;

const SectionColumn = styled(FullWidthSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FeatureBreakdownItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-width: 135px;
  max-width: 280px;

  margin: 0.75rem 32px;
  margin-bottom: 2rem;
`;

const FeatureBreakdownItemsContainer = styled.div`
  margin-top: 4rem;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

const FeatureBreakdownItemIconContainer = styled.div`
  margin-bottom: 1rem;

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    max-width: 125px;
  }
`;

const FeatureBreakdownItemHeading = styled.h3`
  font-size: 1.5rem;
`;

const FeatureBreakdownItemDescription = styled.div`
  font-size: 1.2rem;
  color: #727478;
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1.5rem;

  > * + * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobile}) {
    flex-flow: column nowrap;
    align-items: center;
    > * + * {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }
`;

export type FeatureBreakdownProps =
  ContentfulTemplateComponentProps<IFeatureBreakdownFields>;

export const FeatureBreakdown: React.FC<FeatureBreakdownProps> = ({entry}) => {
  const {
    fields: {
      leadText,
      featureDescriptions,
      background = 'light',
      callsToAction,
    },
  } = entry;

  const backgroundColor = customColors.sectionBackground[background];
  const fontColor = getFontColor(background);

  const ctaButtons = callsToAction
    ? callsToAction.map((cta) => {
        return (
          <CtaButton
            key={cta.sys.id}
            cta={cta}
            color={getButtonColor(cta.fields.pageLink)}
          />
        );
      })
    : null;

  return (
    <SectionColumn style={{backgroundColor, color: fontColor}}>
      {leadText && (
        <BigCenteredContent
          richText={leadText}
          containerProps={{style: {maxWidth: '875px'}}}
        />
      )}

      {featureDescriptions && (
        <FeatureBreakdownItemsContainer>
          {featureDescriptions.map(
            ({sys, fields: {heading, icon, description}}) => {
              return (
                <FeatureBreakdownItem key={sys.id}>
                  {icon && (
                    <FeatureBreakdownItemIconContainer>
                      <Image
                        src={getImageSourceFromAsset(icon)}
                        alt={getImageAltFromAsset(icon)}
                        width={getImageWidthFromAsset(icon)}
                        height={getImageHeightFromAsset(icon)}
                      />
                    </FeatureBreakdownItemIconContainer>
                  )}
                  <FeatureBreakdownItemHeading>
                    {heading}
                  </FeatureBreakdownItemHeading>
                  {description && (
                    <FeatureBreakdownItemDescription>
                      {description}
                    </FeatureBreakdownItemDescription>
                  )}
                </FeatureBreakdownItem>
              );
            }
          )}
        </FeatureBreakdownItemsContainer>
      )}
      {callsToAction && <CTAContainer>{ctaButtons}</CTAContainer>}
    </SectionColumn>
  );
};
