import styled from 'styled-components';

const BackgroundVideo = styled.video`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: center;

  pointer-events: none;
`;

interface BackgroundVideoClipProps {
  src: string;
  type?: React.SourceHTMLAttributes<HTMLSourceElement>['type'];
  videoElementProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
}

export const BackgroundVideoClip: React.FC<BackgroundVideoClipProps> = (
  props
) => {
  const {src, type = 'video/mp4', videoElementProps} = props;

  const _src = window.navigator.userAgent.match('Lighthouse') ? undefined : src;

  return (
    <BackgroundVideo loop muted autoPlay playsInline {...videoElementProps}>
      <source src={_src} type={type} />
    </BackgroundVideo>
  );
};
