/* eslint-disable */
// @ts-nocheck
import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosInstance,
  AxiosStatic,
  AxiosResponse,
} from 'axios';

export type ClientConfig = AxiosRequestConfig;

type ResponseError<E = any> = NonNullable<AxiosResponse<E>>;

interface IRequestError<E = any> {
  info: AxiosError<E> | Error;
  response: ResponseError<E> | null;
}

interface IResponse<T = any, E = any> {
  ok: boolean;
  data?: T | E | null;
  status?: number | null;
  headers?: any;
  err?: IRequestError<E> | null;
}

interface ISuccessResponse<T = any> extends IResponse<T> {
  ok: true;
  data: T;
  status: number;
  headers: any;
  err: null;
}

interface IErrorResponse<E = any> extends IResponse<any, E> {
  ok: false;
  data: null;
  status: number | null;
  headers: any;
  err: IRequestError<E>;
}

interface IServerErrorResponse<E = any> extends IErrorResponse<E> {
  ok: false;
  data: null;
  status: number;
  headers: any;
  err: {
    isCancel: boolean;
    info: AxiosError<E>;
    response: AxiosResponse<E>;
  };
}

interface IFailedRequest extends IErrorResponse<any> {
  ok: false;
  data: null;
  status: null;
  headers: any;
  err: {
    isCancel: boolean;
    info: AxiosError | Error;
    response: null;
  };
}

export type Response<T, E> =
  | ISuccessResponse<T>
  | IServerErrorResponse<E>
  | IFailedRequest;

const processRequestError = <E>(err: any) => {
  // check if this is a axios error with response
  const isCancel = axios.isCancel(err);

  if (err.response) {
    const errWithResponse: IServerErrorResponse<E> = {
      ok: false,
      data: null,
      status: err.response.status,
      headers: err.response.headers,
      err: {
        response: err.response as AxiosResponse<E>,
        info: err,
        isCancel,
      },
    };

    return errWithResponse;
  }

  const unknownError: IFailedRequest = {
    ok: false,
    data: null,
    status: null,
    headers: null,
    err: {response: null, info: err, isCancel},
  };

  return unknownError;
};

const wrapNonDataMethod = (
  client: AxiosInstance,
  method: keyof AxiosStatic
) => {
  return async <T = any, E = any>(
    url: string,
    configs?: ClientConfig
  ): Promise<Response<T, E>> => {
    try {
      const req = client[method];
      const res: AxiosResponse<T> = await req<T>(url, configs);
      return {err: null, ok: true, ...res};
    } catch (err) {
      return processRequestError(err);
    }
  };
};

const wrapDataMethod = (client: AxiosInstance, method: keyof AxiosStatic) => {
  return async <T = any, E = any>(
    url: string,
    data?: object,
    configs?: ClientConfig
  ): Promise<Response<T, E>> => {
    try {
      const req = client[method];
      const res: AxiosResponse<T> = await req<T>(url, data, configs);
      return {err: null, ok: true, ...res};
    } catch (err) {
      return processRequestError<E>(err);
    }
  };
};

/**
 * withWrappedMethods wraps the standard HTTP verbs with types and error handling
 * Once used on an axios client instance, you can pass typings for both success and error cases,
 * and handle them appropriately.
 *
 * @example
 * ```typescript
 * const client = withWrappedMethods(axios.create({baseURL: 'someUrl.com'}));
 * ```
 *
 * @param client the axios instance to wrap
 */
export const withWrappedMethods = (client: AxiosInstance) => {
  const typedClient = {
    ...client,
    get: wrapNonDataMethod(client, 'get'),
    delete: wrapNonDataMethod(client, 'delete'),
    head: wrapNonDataMethod(client, 'head'),
    options: wrapNonDataMethod(client, 'options'),
    post: wrapDataMethod(client, 'post'),
    put: wrapDataMethod(client, 'put'),
    patch: wrapDataMethod(client, 'patch'),
  };

  return typedClient;
};

/**
 * createClient is a wrapper around axios.create that returns the client
 * with typed methods and error handling built in.
 *
 * Example Usage:
 * ```typescript
 * interface ISuccess {
 *  name: string;
 * }
 *
 * interface IFailure {
 *  message: string;
 * }
 *
 * // ... in some async function
 * const http = createClient({baseURL: 'https://someUrl.com'}); // baseURL is optional
 * const res = await http.get<ISuccess, IFailure>('/somePath');
 *
 * let name = res.data.name // compiler error -> Object is possibly 'null'.
 * let status;
 *
 * if (res.ok) {
 *   // is case for any 200 level response
 *   name = res.data.name // works
 *   name = res.data.someOtherThing // compiler error -> Property 'someOtherThing' does not exist on type 'ISuccess'
 * } else {
 *   // could be a server error (e.g. 404) or some other error
 *   let status = res.err.response.status; // compiler error -> Object is possibly 'null'.
 *
 *   if (res.err.response) {
 *     status = res.err.response.status // works
 *     console.log(res.err.response.data.message) // works
 *     console.log(res.err.response.data.randomThing) // compiler error -> Property 'randomThing' does not exist on type 'IFailure'.
 *   } else {
 *     console.log(res.err.info) // some other non server error occurred (type is Error)
 *   }
 * }
 * ```
 * @param config optional, pass any `AxiosRequestConfig`s as you would with axios.create
 */
const createClient = (config?: ClientConfig) => {
  return withWrappedMethods(axios.create(config));
};

/**
 * createCancelController creates {cancel, token} object that can be used to set up a cancellation
 * of a request.
 *
 * Example usage:
 * ```
 * const {cancel, token} = createCancelController();
 * http.get('someUrl.com', {cancelToken: token}).then(res => {
 *   if (!res.ok) {
 *     if (res.err.isCancel) {
 *       // handle cancellation
 *       console.log(res.err.info)
 *     }
 *   }
 * });
 *
 * // cancelling somewhere in the code
 * if (someCondition) {
 *   cancel('A reason this was cancelled')
 * }
 * ```
 *
 */
export const createCancelController = () => axios.CancelToken.source();

export type HttpClient = ReturnType<typeof createClient>;

export default createClient;
