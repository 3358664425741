import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

export const ContentContainer = styled.section`
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    max-width: 1440px;
  }
`;
