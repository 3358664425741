import React, {useState} from 'react';

import {motion} from 'framer-motion';
import {useIntl, FormattedMessage} from 'react-intl';

import {ITabSectionFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

import {ScrollBackground} from './ScrollBackground';
import {ScrollBackground2} from './ScrollBackground2';

type TabSectionProps = ContentfulTemplateComponentProps<ITabSectionFields>;

export const TabSection: React.FC<TabSectionProps> = (props) => {
  const {
    entry: {
      fields: {title, tallImage, tallImage2},
    },
  } = props;

  const intl = useIntl();

  const TrainingPlans = () => (
    <div className="flex flex-col lg:flex-row max-w-7xl mx-auto space-x-8 lg:h-[60vh] justify-center">
      <div className="mt-14">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/5HTNusOewovpMgo8Sx88ye/ba179e833e656df94782a959da176bcb/TTrainingWeb1.png"
          alt=""
          className="max-h-[30vh] lg:max-h-full w-auto object-contain"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/2msEqxBHhzXMrP1q1YpgZA/3ac39e984b7a3d55e3f7cd20019a71bb/TTrainingWeb2.png"
          alt=""
          className="max-h-[30vh] w-auto object-fill"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/2DaAYJvmY0LZxhx9dF6BmQ/955cc96edc8390a2b947f80c38f4677a/TTrainingWeb3.png"
          alt=""
          className="max-h-[30vh] w-auto object-fill"
        />
      </div>
      <div className="mt-14 align-middle justify-center">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/2trDRtcskqCROCPW9sVUM0/83e68156fda7930b451da32b82d4a007/TTrainingWeb4.png"
          alt=""
          className="max-h-[30vh] lg:max-h-full w-auto object-contain"
        />
      </div>
      {/* <div className="flex flex-col items-center justify-center">
        <div className="bg-gray-200 p-4 rounded-[10px]">
          <blockquote className="text-lg italic">
            Stryd has become my daily compass, keeping me on the right track.
          </blockquote>
          <p className="text-right mt-2">- Tester L., Stryd User</p>
        </div>
      </div> */}
    </div>
  );

  const RacePerformance = () => (
    <div className="flex flex-col lg:flex-row max-w-7xl mx-auto space-x-8 lg:h-[60vh]">
      <div className="flex flex-col mt-10 space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/5U2ZJfF4p6CDtZompgtcRU/75c21e1ac64dc98546bfe72a521445aa/RacingWeb1.png"
          alt="Text"
          className="max-h-[20vh] w-auto object-contain self-end"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/3hGbGFhie8BsFuL1fMi3DT/e9858063648ea85a9290386d24b0dbd6/RacingWeb2.png"
          alt="Text"
          className="max-h-[30vh] w-auto object-contain"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8 p-4">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/3osKInJjESqPIjaaJgjqXA/35f17f28c08b23617546d78e97b4182a/RacingWeb3.png"
          alt=""
          className="max-h-[30vh] w-auto object-contain"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/70DIl6VZIdBvbfut9juQQ9/cf9e615b3b018ba1cad1e288d7da76ea/RacingWeb4.png"
          alt=""
          className="max-h-[30vh] w-auto object-fill"
        />
      </div>
    </div>
  );

  const TrainingLoad = () => (
    <div className="flex flex-col lg:flex-row max-w-7xl mx-auto space-x-8 lg:h-[60vh]">
      <div className="mt-14">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/4ZRWzJF7x868PQLiOoyyqZ/795f3d627ab84cb62a4f9582ae55367a/TrainingWeb1.png"
          alt=""
          className="max-h-[30vh] lg:max-h-full w-auto object-contain"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/6wNgMbARlQAEuzunQ4u1V0/f7868e3a1ebba6a5fb304c9eebca5248/TrainingWeb2.png"
          alt=""
          className="max-h-[30vh] w-auto object-fill"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/7spPSYisGMH9Pg0MVgr622/96fc6673682fee761539d3b5314d518f/TrainingWeb3.png"
          alt=""
          className="max-h-[30vh] w-auto object-fill"
        />
      </div>
      <div className="mt-14">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/60Cc8gfnm0OiFDR7AqhZyo/9b13bfa39bb053811d1d10c743f6739f/TrainingWeb4.png"
          alt=""
          className="max-h-[30vh] lg:max-h-full w-auto object-contain"
        />
      </div>
      {/* <div className="flex flex-col items-center justify-center">
        <div className="bg-gray-200 p-4 rounded-[10px]">
          <blockquote className="text-lg italic">
            Stryd has become my daily compass, keeping me on the right track.
          </blockquote>
          <p className="text-right mt-2">- Tester L., Stryd User</p>
        </div>
      </div> */}
    </div>
  );

  const RecoverInjury = () => (
    <div className="flex flex-col lg:flex-row max-w-7xl mx-auto space-x-8 h-[60vh]">
      <div className="flex flex-col mt-10 space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/7JXEc5mLfJX2GCNZUcBXrE/4dd4b4a86bf0dff539918497b14afcb7/Feature1.png"
          alt="Text"
          className="max-h-[10vh] w-auto object-contain self-end"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/17JUPQrdvklvZw0x2s9XvX/ba21966b333219e87540ddc8a999d8a3/Feature2.png"
          alt="Text"
          className="max-h-[10vh] lg:max-h-[40vh] w-auto object-contain"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/4JXg4yVWwnJyT4JVCWbfv3/8591a05f1ba89b23862ee4083f2b4fc1/Feature3.png"
          alt=""
          className="max-h-[10vh] lg:max-h-[30vh] w-auto object-contain"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/6aqRoed5wnSxSnvSR0nhVm/e62ec4a6adf68799645a0f8f41dc821b/Feature5.png"
          alt=""
          className="max-h-[10vh] lg:max-h-[30vh] w-auto object-fill"
        />
      </div>
    </div>
  );

  const ShoeSelection = () => (
    <div className="flex flex-col lg:flex-row max-w-7xl mx-auto space-x-8 h-[60vh]">
      <div className="mt-14">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/7JXEc5mLfJX2GCNZUcBXrE/4dd4b4a86bf0dff539918497b14afcb7/Feature1.png"
          alt=""
          className="max-h-[10vh] lg:max-h-full w-auto object-contain"
        />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8">
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/17JUPQrdvklvZw0x2s9XvX/ba21966b333219e87540ddc8a999d8a3/Feature2.png"
          alt=""
          className="max-h-[10vh] lg:max-h-[30vh] w-auto object-fill"
        />
        <img
          src="https://images.ctfassets.net/8iip9pij3rta/4JXg4yVWwnJyT4JVCWbfv3/8591a05f1ba89b23862ee4083f2b4fc1/Feature3.png"
          alt=""
          className="max-h-[10vh] lg:max-h-[30vh] w-auto object-fill"
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="bg-gray-200 p-4 rounded-[10px]">
          <blockquote className="text-lg italic">
            Stryd has become my daily compass, keeping me on the right track.
          </blockquote>
          <p className="text-right mt-2">- Tester L., Stryd User</p>
        </div>
      </div>
    </div>
  );

  const ElegantTypographySection = () => {
    return (
      <div className="px-8 lg:px-28 py-8 lg:py-24">
        <div className=" mx-auto">
          {/* <p className="text-sm uppercase tracking-wide text-stone-500 mb-4">Innovative Design</p> */}
          <div className="flex flex-col lg:flex-row lg:items-end">
            <div className="lg:w-2/3">
              <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold  text-stone-800 leading-tight mb-6">
                <FormattedMessage
                  id="tabTypography.lineOne"
                  defaultMessage="Upgrade your"
                />
                <br />
                <span className="italic">
                  <FormattedMessage
                    id="tabTypography.lineTwo"
                    defaultMessage="running lifestyle"
                  />
                </span>
              </h2>
            </div>
            <div className="lg:w-1/3 lg:pl-8">
              <p className="text-stone-600 mb-6 font-medium text-lg">
                <FormattedMessage
                  id="tabTypography.supporting"
                  defaultMessage="Transform your running with cutting-edge metrics that feel like
                a natural extension of your training. Stryd molds to your needs,
                offering unparalleled accuracy and personalized coaching."
                />
              </p>
              <div className="flex flex-col sm:flex-row gap-4 p-[3px] justify-end">
                <motion.button
                  className="px-6 py-3 bg-orange-600 text-white font-semibold rounded-full hover:bg-orange-500 transition duration-300"
                  whileHover={{scale: 1.1}}
                  whileTap={{scale: 0.95}}
                >
                  <a href="/store">
                    <FormattedMessage
                      id="precision.buyStryd"
                      defaultMessage="Buy Stryd"
                    />
                  </a>
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PersonalizedTraining = () => {
    return (
      <div className="w-full mx-auto rounded-md overflow-hidden py-10">
        {/* <Vortex
            backgroundColor="black"
            rangeY={900}
            particleCount={500}
            baseHue={10}
            rangeSpeed={1}
            className="flex items-center flex-col justify-center px-2 md:px-10  py-4 w-full h-full"
          > */}
        <div className="flex items-center flex-col justify-center px-8 lg:px-2 md:px-10 py-4 w-full h-full bg-black">
          <h3 className="text-white text-2xl md:text-3xl lg:text-4xl font-thin text-center">
            <FormattedMessage id="precisionUpdate.new" defaultMessage="New" />
          </h3>
          <h2 className="text-white text-5xl md:text-6xl lg:text-7xl font-medium text-center">
            <span className="font-bold">
              <FormattedMessage
                id="precisionUpdate.lineOne"
                defaultMessage="Major Update"
              />
            </span>
            <br />
            <FormattedMessage
              id="precisionUpdate.lineTwo"
              defaultMessage="Precision Run Training"
            />
          </h2>
          <p className="text-white text-sm md:text-2xl max-w-xl mt-6 text-center pb-8">
            <FormattedMessage
              id="precisionUpdate.subHeader"
              defaultMessage="Three New Features To Upgrade your Training"
            />
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-4 lg:grid-rows-4 gap-x-4 gap-y-4 max-w-7xl mx-auto w-full h-auto min-h-0 auto-rows-min">
            <div className="col-span-1 row-span-1 lg:col-span-2 lg:row-span-2 border-4 border-pink-600 rounded-[20px] p-8 h-auto min-h-0">
              <div className="max-w-[42rem]">
                <img
                  src="https://images.ctfassets.net/8iip9pij3rta/2NZUWK9kF9T28vNkom9oml/ab972dccd4ccb677cde0eb59c9eca5b3/GeneratedWorkoutsWeb.png"
                  alt=""
                />
                <div className="py-8">
                  <h2 className="text-left text-balance text-4xl lg:text-5xl font-semibold text-white">
                    <FormattedMessage
                      id="precisionUpdate.featureOne"
                      defaultMessage="Generated Workouts"
                    />
                  </h2>
                  <p className="mt-4 text-left text-lg font-medium text-neutral-200">
                    <FormattedMessage
                      id="precisionUpdate.featureOneSupporting"
                      defaultMessage="Experience workouts that seamlessly fit into your routine and
                        evolve with your progress. Stryd's Generated Workouts
                        utilize precise data from your Stryd Footpod to create
                        personalized sessions that adapt to your schedule and
                        preferences."
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="row-start-2 col-span-1 row-span-1 lg:col-start-3 lg:col-span-2 lg:row-span-2 border-4 border-orange-600 rounded-[20px] p-8 h-auto min-h-0">
              <img
                src="https://images.ctfassets.net/8iip9pij3rta/4xNY6mrGB7PCJKOHhwIJV5/1f44020fe5714f4e130570632b9bf255/SingleFootpathWeb.png"
                alt=""
              />
              <div className="py-8">
                <h2 className="text-left text-balance text-4xl lg:text-5xl font-semibold text-white">
                  <FormattedMessage
                    id="precisionUpdate.featureTwo"
                    defaultMessage="Stryd Footpath"
                  />
                </h2>
                <p className="mt-4 max-w-[42rem] text-left text-lg font-medium text-neutral-200">
                  <FormattedMessage
                    id="precisionUpdate.featureTwoSupporting"
                    defaultMessage="Stryd Footpath offers a new way to understand and improve your
                      running. By translating complex performance metrics into
                      easy-to-understand visuals, you can make informed decisions that
                      keep you running stronger, smarter, and healthier — now all from
                      a single, Next Gen Stryd pod."
                  />
                </p>
              </div>
            </div>
            <div className="row-start-3 col-span-1 row-span-1 lg:col-span-4 lg:row-start-3 lg:row-span-2 border-4 border-blue-600 rounded-[20px] p-8 h-auto min-h-0">
              <div className="flex flex-col lg:flex-row">
                <img
                  src="https://images.ctfassets.net/8iip9pij3rta/2bsUxeYngN2F8cMJewr35O/e0a06e768a5315229ea4c3c90eef0ec5/RunFormStatsWeb.png"
                  alt=""
                  className="object-fill lg:max-w-[50vw]"
                />
                <div className="flex flex-col py-8 lg:px-8">
                  <h2 className="text-left text-balance text-4xl lg:text-5xl font-semibold text-white">
                    <FormattedMessage
                      id="precisionUpdate.featureThree"
                      defaultMessage="Run Form & Shoe Analytics"
                    />
                  </h2>
                  <p className="mt-4 text-left text-lg font-medium text-neutral-200">
                    <FormattedMessage
                      id="precisionUpdate.featureThreeSupporting"
                      defaultMessage="Stryd's new Run Form & Shoe Analytics feature gives
                          you the tools to compare your data and unlock meaningful
                          insights to elevate your performance. By analyzing key
                          metrics across a variety of factors, you can make smarter
                          decisions to improve your running, tailored to your
                          individual needs."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-4 my-10">
            <motion.button
              className="px-6 py-3 bg-orange-600 text-white font-semibold rounded-full hover:bg-orange-500 transition duration-300"
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.95}}
            >
              <a href="/store">
                <FormattedMessage
                  id="precision.buyStryd"
                  defaultMessage="Buy Stryd"
                />
              </a>
            </motion.button>

            <motion.button
              className="px-6 py-3 bg-blue-600 text-white font-semibold hover:bg-blue-500 rounded-full transition duration-300"
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.95}}
            >
              <a href="https://blog.stryd.com/2024/10/01/major-update-precision-run-training/">
                <FormattedMessage
                  id="precision.learnMore"
                  defaultMessage="Learn more about Precision Training"
                />
              </a>
            </motion.button>
          </div>
        </div>
        {/* </Vortex> */}
      </div>
    );
  };

  const useCases = [
    {
      title: intl.formatMessage({
        id: 'useCase.headerOne',
        defaultMessage: 'Pick a Training Plan',
      }),
      component: TrainingPlans,
    },
    {
      title: intl.formatMessage({
        id: 'useCase.headerTwo',
        defaultMessage: 'Plan for a Race',
      }),
      component: RacePerformance,
    },
    {
      title: intl.formatMessage({
        id: 'useCase.headerThree',
        defaultMessage: 'Monitor Training Load',
      }),
      component: TrainingLoad,
    },
    // {
    //   title: intl.formatMessage({
    //     id: 'useCase.headerFour',
    //     defaultMessage: 'Recover from Injury',
    //   }),
    //   component: RecoverInjury,
    // },
    // {
    //   title: intl.formatMessage({
    //     id: 'useCase.headerFive',
    //     defaultMessage: 'Select Your Ideal Shoe',
    //   }),
    //   component: ShoeSelection,
    // },
  ];

  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <PersonalizedTraining />
      {tallImage && (
        <ScrollBackground image={tallImage.fields.file.url}></ScrollBackground>
      )}
      <ElegantTypographySection />
      <div className="max-w-7xl mx-auto p-6">
        <div className="flex flex-col justify-center border-b border-gray-200 md:flex-col lg:flex-row">
          {useCases.map((useCase, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(index)}
              className={`p-3 lg:px-8 lg:py-8 text-lg font-bold transition-colors duration-200 ${
                activeTab === index
                  ? 'text-orange-600 border-b-2 border-orange-600'
                  : 'text-slate-500 hover:text-slate-700'
              }`}
            >
              {useCase.title}
            </button>
          ))}
        </div>
        <motion.div
          key={activeTab}
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          transition={{duration: 0.3}}
          className="p-6 rounded-lg w-full flex items-center justify-center"
        >
          {React.createElement(useCases[activeTab].component)}
        </motion.div>
      </div>
      {tallImage2 && (
        <ScrollBackground2
          image={tallImage2.fields.file.url}
        ></ScrollBackground2>
      )}
    </>
  );
};
