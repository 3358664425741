import React from 'react';

import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {BackgroundNextImage} from 'src/components/BackgroundNextImage';
import {CtaButton} from 'src/components/CtaButton';
import {
  RichText,
  FluidContainer,
  ContentContainer,
} from 'src/features/contentful/components';
import {BrandCards} from 'src/features/contentful/components';
import {
  getBackgroundColor,
  getButtonColor,
  getFontColor,
  getImageAltFromAsset,
  getImageSourceFromAsset,
  ITextSectionFields,
} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../types';

const {tabletPortrait} = dimensions.breakpoints;

const TextSectionContainer = styled(FluidContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem;

  @media screen and (min-width: ${tabletPortrait}) {
    padding: 5rem 0;
  }
`;

const RichTextContainer = styled.div<{overrideCSS?: string}>`
  text-align: center;

  h2 {
    font-size: 3.75rem;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  ${({overrideCSS}) => overrideCSS}
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
  margin-top: 3rem;

  > * + * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    width: 100%;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    flex-flow: column nowrap;
    align-items: center;
    > * + * {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }
`;

type TextSectionProps = ContentfulTemplateComponentProps<ITextSectionFields>;

const templates = {
  brandCards: BrandCards,
};

export const TextSection: React.FC<TextSectionProps> = ({entry}) => {
  const {
    fields: {
      textContent,
      background = 'light',
      backgroundImage,
      callsToAction,
      bodySize,
      components = [],
      css,
    },
  } = entry;

  const backgroundColor = getBackgroundColor(background, backgroundImage);
  const fontColor = getFontColor(background, backgroundImage);

  const ctaButtons = callsToAction
    ? callsToAction.map((cta) => {
        return (
          <CtaButton
            key={cta.sys.id}
            cta={cta}
            color={getButtonColor(cta.fields.pageLink)}
          />
        );
      })
    : null;

  return (
    <TextSectionContainer style={{backgroundColor, color: fontColor}}>
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <ContentContainer>
        <RichTextContainer overrideCSS={css}>
          <RichText content={textContent} bodySize={bodySize} />
        </RichTextContainer>

        {components.map((entry, index) => {
          const Comp = templates[entry.sys.contentType.sys.id];

          return <Comp key={index} entry={entry} />;
        })}

        {callsToAction && <CTAContainer>{ctaButtons}</CTAContainer>}
      </ContentContainer>
    </TextSectionContainer>
  );
};
