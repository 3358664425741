import {Fragment} from 'react';
import React from 'react';

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {BLOCKS, Document, Node} from '@contentful/rich-text-types';
import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
} from 'src/utils/contentful';
import {ITextSectionFields} from 'src/utils/contentful/content-models/generated';

const {mobile} = dimensions.breakpoints;

const RichTextContainer = styled.div<{
  bodySize?: ITextSectionFields['bodySize'];
  overrideCSS?: string;
}>`
  line-height: 1.35;
  width: 100%;

  h1 {
    margin-bottom: 2rem;

    @media screen and (min-width: ${mobile}) {
      margin-bottom: 3.25rem;
    }
  }

  h2 {
    @media screen and (min-width: ${mobile}) {
      margin-bottom: 2.25rem;
    }
  }

  h3 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
  }

  br {
    display: none;

    @media screen and (min-width: ${mobile}) {
      display: inline;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${(props) => props?.overrideCSS}
`;

const ImageWrapper = styled.div`
  margin: 4rem 0;
`;

/* See https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/ for other renderOptions for videos, links, etc. */
const renderOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const {target} = node.data;
      return (
        <ImageWrapper>
          <Image
            src={getImageSourceFromAsset(target)}
            alt={getImageAltFromAsset(target)}
            width={getImageWidthFromAsset(target)}
            height={getImageHeightFromAsset(target)}
          />
        </ImageWrapper>
      );
    },
    // Remove empty paragraphs
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (!children || !children.toString()) return null;
      return <p>{children}</p>;
    },
  },
  renderText: (text) => {
    // Render line breaks in contentful richtext editor.

    return (
      text
        // Remove u2028 line breaks
        // https://github.com/contentful/rich-text/issues/96
        .replace(/\u2028/g, '')
        .split('\n')
        .flatMap((text, i) =>
          text
            ? [
                i > 0 && (
                  <React.Fragment key={`${i}-br`}>
                    {' '}
                    <br />
                  </React.Fragment>
                ),
                <Fragment key={i}>{text}</Fragment>,
              ]
            : []
        )
    );
  },
};

interface RichTextProps {
  content: Document;
  bodySize?: ITextSectionFields['bodySize'];
  overrideCSS?: string;
}

export const RichText: React.FC<RichTextProps> = (props) => {
  const {content, bodySize, overrideCSS} = props;

  return (
    <RichTextContainer bodySize={bodySize} overrideCSS={overrideCSS}>
      {documentToReactComponents(content, renderOptions)}
    </RichTextContainer>
  );
};
