import {ClientConfig, HttpClient} from '../../http';
import {FeaturesGroup, FeaturesRequestParams} from '@stryd/models';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';

const basePath = `/b/features`;

export const setupFeaturesEndpoints = (client: HttpClient) => {
  return {
    /**
     * Get a map of feature group to list of features with access levels
     *
     * Available Query Params:
     * * `access_level`: default `true`
     * * `pioneer`: default `false`
     * * `locale`: default `en`
     */
    getFeatures: (
      params?: Partial<FeaturesRequestParams>,
      config?: ClientConfig
    ) => {
      const query = params ? '?' + toURLParams(params) : '';
      const url = basePath + query;
      return client.get<FeaturesGroup[], string>(url, config);
    },
  };
};
