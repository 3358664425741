import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles/colors';

const BoxOuterContainer = styled.article`
  width: 100%;
  max-width: 750px;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  border-radius: ${dimensions.defaultBorderRadius};
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
`;

const ColumnContainer = styled.div`
  flex: 1;
  min-height: 385px;
  width: 100%;
  min-width: 375px;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    min-height: unset;
    min-width: 300px;
  }
`;

const MainColumnContainer = styled(ColumnContainer)`
  order: 1;
  background-color: ${customColors.asideBackground};
  color: ${customColors.asideTextColor};
`;

const SecondaryColumnContainer = styled(ColumnContainer)`
  order: 2;
`;

export interface TwoColumnInfoBoxProps {
  mainContent: React.ReactNode;
  secondaryContent: React.ReactNode;
}

export const TwoColumnInfoBox: React.FC<TwoColumnInfoBoxProps> = ({
  mainContent,
  secondaryContent,
}) => {
  return (
    <BoxOuterContainer>
      <MainColumnContainer>{mainContent}</MainColumnContainer>
      <SecondaryColumnContainer>{secondaryContent}</SecondaryColumnContainer>
    </BoxOuterContainer>
  );
};
