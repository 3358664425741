import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';
import {
  GetPromotionCodeByCodeOptions,
  GetPromotionCodeByIdOptions,
} from '@stryd/models';

const promotionCodePath = `/canyon/admin/promotion-codes`;

export const setupPromotionCodeEndpoints = (client: HttpClient) => {
  return {
    getPromotionCodesByCode: (
      query: GetPromotionCodeByCodeOptions,
      config?: ClientConfig
    ) => {
      return client.get(
        `${promotionCodePath}/search?${toURLParams(query)}`,
        config
      );
    },
    getPromotionCodesById: (
      query: GetPromotionCodeByIdOptions,
      config?: ClientConfig
    ) => {
      return client.get(
        `${promotionCodePath}/search?${toURLParams(query)}`,
        config
      );
    },
  };
};
