import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {largeH2, largeH3} from 'src/styles';
import {
  IEmailListSignupKlaviyoFields,
  getImageSourceFromAsset,
  getImageAltFromAsset,
  getFontColor,
  getBackgroundColor,
} from 'src/utils/contentful';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {FullWidthSection} from '../../FullWidthSection';
import KlaviyoEmbedLarge from '../../layout/KlaviyoEmbedLarge';
import {ContentfulTemplateComponentProps} from '../types';

type EmailListSignupProps =
  ContentfulTemplateComponentProps<IEmailListSignupKlaviyoFields>;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  margin-bottom: 1rem;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    max-width: 85%;
  }
  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    max-width: 100%;
  }
`;

const Description = styled.div`
  margin-bottom: 3rem;
  h2 {
    ${largeH2}
  }

  h3 {
    ${largeH3}
  }

  p {
    font-size: 1.5rem;
  }
`;

export const EmailListSignupKlaviyo: React.FC<EmailListSignupProps> = (
  props
) => {
  const {fields} = props.entry;
  const {background = 'dark', backgroundImage} = fields;

  const backgroundColor = getBackgroundColor(background, backgroundImage);
  const fontColor = getFontColor(background, backgroundImage);

  return (
    <FullWidthSection style={{backgroundColor, color: fontColor}}>
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <ContentContainer>
        <KlaviyoEmbedLarge />
      </ContentContainer>
    </FullWidthSection>
  );
};
