import React, {useState} from 'react';

import {IHeroVideoFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

import HeroVideoDialog from '@/components/magicui/hero-video-dialog';

type HeroVideoProps = ContentfulTemplateComponentProps<IHeroVideoFields>;

export const HeroVideo: React.FC<HeroVideoProps> = (props) => {
  const {
    entry: {
      fields: {url, thumbnail},
    },
  } = props;

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh]">
      <h1 className="text-6xl font-bold mb-24">And makes running fun.</h1>
      {url && thumbnail && (
        <div className="w-3/5 mx-auto">
          <HeroVideoDialog
            className="dark:hidden block"
            animationStyle="top-in-bottom-out"
            videoSrc={url}
            thumbnailSrc={thumbnail.fields.file.url as string}
            thumbnailAlt="Hero Video"
          />
          <HeroVideoDialog
            className="hidden dark:block"
            animationStyle="top-in-bottom-out"
            videoSrc={url}
            thumbnailSrc={thumbnail.fields.file.url as string}
            thumbnailAlt="Hero Video"
          />
        </div>
      )}
    </div>
  );
};
