import {useState} from 'react';

import Image from 'next/image';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';
import {colors} from '@stryd/react-ui';
import {isNumber, stripeAmountToCurrencyAmount} from '@stryd/util-lib';

import {regionConfigs} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {useCart} from 'src/features/cart/contexts';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {usePriceFormatters} from 'src/hooks/usePriceFormatters';
import {colors as customColors} from 'src/styles/colors';
import {MoneyBag} from 'src/types';
import {addToCartEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';
import {variantToItems} from 'src/utils/analytics/variantToItems';
import {
  IFeaturedProductSectionFields,
  IProduct,
  IProductVariant,
} from 'src/utils/contentful/content-models/generated/contentful';
import {getImageSourceFromAsset} from 'src/utils/contentful/content-models/util';

import {Link} from '../../Link';
import {TwoColumnInfoBoxSection} from '../../layout/TwoColumnInfoBoxSection';
import {ContentfulTemplateComponentProps} from '../types';

const ContentBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  min-height: 380px;
`;

const imageSize = '175px';

const ProductImageContainer = styled.div`
  position: relative;
  width: ${imageSize};
  height: ${imageSize};
  margin-bottom: ${dimensions.twicePadding};
`;

const AddToCartButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const ErrorMessage = styled.span`
  color: red;
  max-width: 200px;
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  height: 100%;
  text-align: center;
`;

const ProductTitle = styled.p`
  font-size: 3.25rem;
  line-height: 1.1;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: ${customColors.asideTextColor};
`;

const ProductSubtitle = styled.p`
  font-size: 1.25rem;
  margin: 0;
  color: ${customColors.asideTextColorSecondary};
`;

const ProductInfoGroupContainer = styled.div`
  margin-bottom: ${dimensions.twicePadding};
`;

const PrimaryPrice = styled.p`
  color: ${colors.themes.dark.accentPrimary};
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: ${dimensions.halfPadding};
`;

const SecondaryPrice = styled.p`
  font-size: 0.9rem;
  margin: 0;
`;

const LinkContainer = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: ${customColors.asideTextColorSecondary};
`;

interface AddToCartContentBoxProps {
  product: IProduct;
  productVariant: IProductVariant;
  image: {
    src: string;
    alt: string;
  };
}

const AddToCartContentBox: React.FC<AddToCartContentBoxProps> = (props) => {
  const {product, productVariant, image} = props;
  const [cart, cartDispatch] = useCart();
  const {region, locale} = usePageRegionData();
  const router = useRouter();
  const [maxQtyErr, setMaxQtyErr] = useState(false);

  const {currency} = regionConfigs[region];

  const {oneTimePricesTotal, recurringPricesTotals} = getVariantPricing({
    variant: productVariant,
    region,
    currency,
  });

  const firstRecurringUnitAmount = recurringPricesTotals.reduce(
    (total, price) => {
      return total + price.unit_amount;
    },
    0
  );

  const handleAddToCart = () => {
    const {maxCheckoutQuantity} = productVariant.fields;
    const itemInCart = cart.lineItems.find(
      (item) => item.selectedVariant.sys.id === productVariant.sys.id
    );

    if (
      itemInCart &&
      isNumber(maxCheckoutQuantity) &&
      itemInCart.quantity >= maxCheckoutQuantity
    ) {
      setMaxQtyErr(true);
      return;
    }

    track(
      addToCartEvent({
        ecommerce: {
          currency: oneTimePricesTotal.currency,
          value: stripeAmountToCurrencyAmount(
            oneTimePricesTotal.unit_amount + firstRecurringUnitAmount,
            oneTimePricesTotal.currency
          ),
          items: variantToItems(productVariant),
        },
      })
    );

    cartDispatch({
      type: 'ADD_QTY_TO_CART',
      region,
      quantity: 1,
      item: {product, selectedVariant: productVariant},
    });

    router.push(`/${region}/${locale}/cart`);
  };

  return (
    <ContentBoxContainer style={{justifyContent: 'space-around'}}>
      <ProductImageContainer>
        <Image priority={true} layout="fill" objectFit="contain" {...image} />
      </ProductImageContainer>
      <AddToCartButtonContainer>
        <BorderOnlyActionButton onClick={handleAddToCart}>
          <FormattedMessage
            id="actions.addToCart"
            defaultMessage="Add to Cart"
          />
        </BorderOnlyActionButton>
        {maxQtyErr && (
          <ErrorMessage role="alert">
            <FormattedMessage
              id="errors.addToCartQuantityError"
              defaultMessage="You cannot add more of this item to your cart."
            />
          </ErrorMessage>
        )}
      </AddToCartButtonContainer>
    </ContentBoxContainer>
  );
};

interface ProductInfoContentBoxProps {
  productContent: IFeaturedProductSectionFields;
}

const ProductInfoContentBox: React.FC<ProductInfoContentBoxProps> = (props) => {
  const {
    productContent: {
      productTitle,
      productSubtitle,
      featuredProductVariant,
      detailsLinkText,
      detailsLinkUrl,
    },
  } = props;

  const {region, locale} = usePageRegionData();
  const {formatOneTimePrice, formatRecurringPrice, formatCommitmentPrice} =
    usePriceFormatters({
      locale,
      includeCommitment: true,
    });

  const {currency} = regionConfigs[region];

  const {oneTimePricesTotal, recurringPricesTotals} = getVariantPricing({
    variant: featuredProductVariant,
    region,
    currency,
  });

  return (
    <ContentBoxContainer>
      <ProductInfoContainer>
        <ProductInfoGroupContainer>
          <ProductTitle>{productTitle}</ProductTitle>
          <ProductSubtitle>{productSubtitle}</ProductSubtitle>
        </ProductInfoGroupContainer>

        <ProductInfoGroupContainer>
          <PrimaryPrice>{formatOneTimePrice(oneTimePricesTotal)}</PrimaryPrice>

          {recurringPricesTotals.map((priceTotal) => {
            if (priceTotal.min_recurring_intervals) {
              const lifeTimeUnitAmount =
                priceTotal.unit_amount * priceTotal.min_recurring_intervals +
                oneTimePricesTotal.unit_amount;

              const lifeTimePrice: MoneyBag = {
                ...oneTimePricesTotal,
                unit_amount: lifeTimeUnitAmount,
              };

              return (
                <SecondaryPrice
                  key={`${priceTotal.unit_amount}-${priceTotal.interval}`}
                >
                  + {formatCommitmentPrice(priceTotal, lifeTimePrice)}
                </SecondaryPrice>
              );
            }

            return (
              <SecondaryPrice
                key={`${priceTotal.unit_amount}-${priceTotal.interval}`}
              >
                + {formatRecurringPrice(priceTotal)}
              </SecondaryPrice>
            );
          })}
        </ProductInfoGroupContainer>

        {detailsLinkUrl ? (
          <LinkContainer>
            <Link
              style={{textDecoration: 'underline', paddingBottom: '3px'}}
              href={detailsLinkUrl}
            >
              {detailsLinkText}
            </Link>
          </LinkContainer>
        ) : null}
      </ProductInfoContainer>
    </ContentBoxContainer>
  );
};

type FeaturedProductSectionProps =
  ContentfulTemplateComponentProps<IFeaturedProductSectionFields>;

export const FeaturedProductSection: React.FC<FeaturedProductSectionProps> = ({
  entry,
}) => {
  const {fields} = entry;
  const {
    product,
    featuredProductVariant,
    productImage,
    relatedInfoLinkUrl,
    relatedInfoLinkText,
  } = fields;

  const link = relatedInfoLinkUrl
    ? {
        href: relatedInfoLinkUrl,
        text: relatedInfoLinkText,
        style: {color: 'unset'},
        hoverStyle: {color: colors.themes.dark.textHighlight},
      }
    : undefined;

  return (
    <TwoColumnInfoBoxSection
      mainContent={<ProductInfoContentBox productContent={fields} />}
      secondaryContent={
        <AddToCartContentBox
          product={product}
          productVariant={featuredProductVariant}
          image={{
            src: getImageSourceFromAsset(productImage),
            alt: productImage.fields.description || productImage.fields.title,
          }}
        />
      }
      link={link}
    />
  );
};
