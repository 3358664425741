import styled from 'styled-components';

import {BorderOnlyActionButton, colors} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles';
import {IPageLinkCtaFields} from 'src/utils/contentful/content-models/generated/contentful';

import {Link} from './Link';

const getButtonHoverFontColor = ({accentColor}: {accentColor?: string}) => {
  if (accentColor === 'white' || accentColor === '#ffffff') {
    return customColors.textDark;
  }

  return colors.themes.dark.headerText;
};

const CtaButton = styled(BorderOnlyActionButton)<{accentColor?: string}>`
  border-radius: 9999px;
  &:hover {
    color: ${getButtonHoverFontColor};
  }
`;

interface CtaLinkButton extends IPageLinkCtaFields {
  color?: string;
}

export const CtaLinkButton: React.FC<CtaLinkButton> = (props) => {
  const {buttonText, pageLink, children, color} = props;

  return (
    <Link href={pageLink}>
      <CtaButton accentColor={color}>
        {buttonText}
        {children}
      </CtaButton>
    </Link>
  );
};
