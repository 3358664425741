import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {ITestimonialsContainerFields} from 'src/utils/contentful';

import {FullWidthSection} from '../../FullWidthSection';
import {Testimonial} from '../../Testimonial';
import {ContentfulTemplateComponentProps} from '../types';

type TestimonialsContainerProps =
  ContentfulTemplateComponentProps<ITestimonialsContainerFields>;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TestimonialsGrid = styled.div`
  display: block;
  box-sizing: border-box;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  place-content: flex-start center;
  align-items: flex-start;
  grid-auto-flow: row dense;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  column-count: 3;
  column-gap: 1rem;
  column-rule-style: none;
  column-rule-width: 0px;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    column-count: 2;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    column-count: 1;
  }
`;

export const TestimonialsContainer: React.FC<TestimonialsContainerProps> = (
  props
) => {
  const {
    entry: {
      fields: {text, testimonials},
    },
  } = props;

  return (
    <FullWidthSection style={{flexDirection: 'column'}}>
      <Text
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(text),
        }}
      />
      <TestimonialsGrid>
        {testimonials.map((testimonial, index) => {
          return (
            <Testimonial
              key={`${testimonial.sys.id}-${index}`}
              name={testimonial.fields.name}
              image={testimonial.fields.image}
              body={testimonial.fields.body}
            />
          );
        })}
      </TestimonialsGrid>
    </FullWidthSection>
  );
};
