import {HttpClient, ClientConfig} from '../../http';
import {
  IActivity,
  ActivityAdmin,
  IFitDownloadResponse,
  IScaleParameters,
  GetActivityFootDataResponse,
} from '@stryd/models';

const activityPath = `/b/admin/activities`;

export const setupActivityEndpoints = (client: HttpClient) => {
  return {
    getById: (activityId: string | number, config?: ClientConfig) => {
      return client.get<ActivityAdmin, string>(
        `${activityPath}/${activityId}`,
        config
      );
    },

    editById: (
      activityId: string | number,
      data: Partial<IActivity>,
      config?: ClientConfig
    ) => {
      // returns activity ID
      return client.put<IActivity, string>(
        `${activityPath}/${activityId}`,
        data,
        config
      );
    },

    deleteById: (activityId: string | number, config?: ClientConfig) => {
      // returns activity ID
      return client.delete<number, string>(
        `${activityPath}/${activityId}`,
        config
      );
    },

    restoreById: (activityId: string | number, config?: ClientConfig) => {
      // returns activity ID
      return client.put<number, string>(
        `${activityPath}/${activityId}/restore`,
        config
      );
    },

    scaleById: (
      activityId: string | number,
      scaleParameters: IScaleParameters,
      config?: ClientConfig
    ) => {
      // server response with "Done!" if successful edit
      return client.put<string, string>(
        `${activityPath}/${activityId}/scale?ratio=${scaleParameters.ratio}&type=${scaleParameters.type}`,
        config
      );
    },

    getIRByID: (activityId: string | number, config?: ClientConfig) => {
      return client.get<IActivity, string>(
        `/b/admin/activityir/${activityId}`,
        config
      );
    },

    downloadFit: (
      username: string,
      activityId: string | number,
      config?: ClientConfig
    ) => {
      return client.get<IFitDownloadResponse, string>(
        `${activityPath}/${username}/${activityId}/fit`,
        config
      );
    },

    getIRWeight: (activityId: string | number, config?: ClientConfig) => {
      return client.get<{weight: number}, string>(
        `/b/admin/activities/${activityId}/weight`,
        config
      );
    },

    removeAirPower: (activityId: string | number, config?: ClientConfig) => {
      return client.delete<string, string>(
        `${activityPath}/${activityId}/airpower`,
        config
      );
    },

    getActivityFootData: (
      {
        activityId,
        includeContent = true,
      }: {activityId: string | number; includeContent?: boolean},
      config?: ClientConfig
    ) => {
      return client.get<GetActivityFootDataResponse, string>(
        `/b/admin/activities/${activityId}/footdata?include_content=${includeContent}`,
        config
      );
    },
  };
};
