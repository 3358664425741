import {IWorkout, IBlock, UserWorkoutBlockEstimate} from '@stryd/models';
import {ClientConfig, HttpClient} from '../../http';

export const setupWorkoutEndpoints = (client: HttpClient) => {
  const base = `/b/api/v1`;
  const urlPrefix = `/b/api/v1/workouts`;

  return {
    getWorkout: (id: string, configs?: ClientConfig) => {
      return client.get<IWorkout, string>(`${urlPrefix}/${id}`, configs);
    },

    getWorkoutEstimate: (workoutTemplate: IWorkout, configs?: ClientConfig) => {
      return client.post<UserWorkoutBlockEstimate[], string>(
        `${base}/users/workouts/estimate`,
        workoutTemplate,
        configs
      );
    },

    getWorkoutBlockEstimate: (block: IBlock, configs?: ClientConfig) => {
      return client.post<UserWorkoutBlockEstimate, string>(
        `${base}/users/workouts/estimate/block`,
        block,
        configs
      );
    },
  };
};
