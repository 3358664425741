import * as React from 'react';

import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {getImageSourceFromAsset} from 'src/utils/contentful';
import {
  IBrandCards,
  IFeatureDescription,
} from 'src/utils/contentful/content-models';

const BrandCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 200px;
  padding: 1rem 0;
  margin: 1rem auto;
  background-color: #fff;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  text-align: center;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.32);
  }

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    margin: 0 20px 1rem 20px;
    padding: 24px 0;
    width: 180px;
    height: 260px;
  }
`;

const BrandCardLinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 200px;
  padding: 1rem 0;
  margin: 1rem auto;
  background-color: #fff;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  text-align: center;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.32);
  }

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    margin: 0 20px 1rem 20px;
    padding: 24px 0;
    width: 180px;
    height: 260px;
  }
`;

export const BrandCard: React.FC<{entry: IFeatureDescription}> = ({entry}) => {
  const {heading, icon, externalLink} = entry.fields;

  const card = (
    <>
      <p>
        {icon ? (
          <img
            src={getImageSourceFromAsset(icon)}
            height={145}
            alt={heading}
            loading="lazy"
          />
        ) : null}
      </p>
      <p>{heading}</p>
    </>
  );

  if (externalLink) {
    return (
      <BrandCardLinkContainer href={externalLink}>
        {card}
      </BrandCardLinkContainer>
    );
  }

  return <BrandCardContainer>{card}</BrandCardContainer>;
};

const BrandCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    padding: 5rem 0;
    margin: 3rem 0 -1rem 0;
  }
`;

export const BrandCards: React.FC<{entry: IBrandCards}> = ({entry}) => {
  if (!entry.fields.components) return null;

  return (
    <BrandCardsContainer>
      {entry.fields.components?.map((card, index) => (
        <BrandCard key={index} entry={card} />
      ))}
    </BrandCardsContainer>
  );
};
