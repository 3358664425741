import * as React from 'react';

import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {RichText} from 'src/features/contentful/components';
import {
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
} from 'src/utils/contentful';
import {ITestimonial} from 'src/utils/contentful/content-models/generated';

const TestimonialCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 0 0 370px;
  height: 388px;
  padding: 11px 18px 40px 26px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  transition: box-shadow 0.3s;
  text-align: left;
  background: #fff;

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.32);
  }

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    flex: 0 0 370px;
  }
`;

const TestimonialCardMeta = styled.div`
  display: flex;
  align-items: center;
`;

const TestimonialCardAvatar = styled.div`
  width: 46px;
  height: 46px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
`;

const TestimonialCardPerson = styled.div`
  font-size: 14px;
  color: #545659;
`;

const RichTextContainer = styled.div`
  overflow-y: scroll;
  margin-top: 1rem;
  padding-right: 14px;

  &::-webkit-scrollbar {
    display: none;
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    padding-right: 8px;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      display: unset;
    }
  }

  h4 {
    font-size: 16px;
    color: #000000;
    margin-bottom: 4px;
    text-align: left;
  }
  p {
    font-size: 14px;
    color: #545659;
    text-align: left;
    margin-bottom: 1rem;
  }
`;

export const TestimonialCard: React.FC<{testimonial: ITestimonial}> = (
  props
) => {
  const {
    testimonial: {
      fields: {name, image, body},
    },
  } = props;
  return (
    <TestimonialCardContainer>
      <TestimonialCardMeta>
        <TestimonialCardAvatar>
          <Image
            src={getImageSourceFromAsset(image)}
            alt={getImageAltFromAsset(image)}
            width={getImageWidthFromAsset(image)}
            height={getImageHeightFromAsset(image)}
          />
        </TestimonialCardAvatar>
        <TestimonialCardPerson>{name}</TestimonialCardPerson>
      </TestimonialCardMeta>
      <RichTextContainer>
        <RichText content={body} />
      </RichTextContainer>
    </TestimonialCardContainer>
  );
};
