import {format, getUnixTime} from 'date-fns';
import {HttpClient, ClientConfig} from '../../http';
import {
  IUserSearchParameters,
  IUser,
  IUserAdmin,
  IAdminUpdatePrefs,
  IGetActivityCalendarParameters,
  IActivityCalendar,
  IDateRange,
  IAdminAutoCPResults,
  IRunBalanceHistory,
  IUserIdentifier,
  IOfflineFileDownload,
  CriticalPowerHistory,
  IOfflineFilesListParams,
  IUserCalendarClientParams,
  IUserCalendarRequestParams,
  IUserCalendarResponse,
  IOAuthUserIR,
  IAdvancedSearchParams,
  IAvailableFilters,
  FeatureAccess,
  GrantFeatureAccessOptions,
  RevokeFeatureAccessOptions,
  UserPermissionGroup,
} from '@stryd/models';
import {
  typedObjectKeys,
  toURLParams,
  calendarTypeToCode,
} from '@stryd/util-lib';

const userPath = `/b/admin/users`;

export const createDateRangeString = (date_ranges: IDateRange[]) => {
  let dateRangeString = '';
  date_ranges.forEach((dateRange: IDateRange) => {
    const fromStr = format(dateRange.startDate, 'MM.dd.yyyy');
    const toStr = format(dateRange.endDate, 'MM.dd.yyyy');
    dateRangeString += `_${fromStr}-${toStr}`;
  });
  // the dateRangeString will start with a _, so return after index 0
  return dateRangeString.substring(1);
};

export const setupUserEndpoints = (client: HttpClient) => {
  return {
    search: (params: IUserSearchParameters, config?: ClientConfig) => {
      const urlParams: string = toURLParams({
        u: params.user_name,
        e: params.email,
        id: params.id,
      });
      return client.get<IUserAdmin[], string>(
        `${userPath}/search?${urlParams}`,
        config
      );
    },

    advancedSearch: (params: IAdvancedSearchParams, config?: ClientConfig) => {
      return client.post<string, string>(
        `${userPath}/advancedsearch`,
        params,
        config
      );
    },

    getAdvancedSearchFields: (config?: ClientConfig) => {
      return client.get<IAvailableFilters, string>(
        `${userPath}/advancedsearch/fields`,
        config
      );
    },

    sendPasswordResetLink: (email: string, config?: ClientConfig) => {
      return client.post<string, string>(
        `/b/reset/password?email=${email}`,
        config
      );
    },

    editById: (
      userID: string | number,
      data: Partial<IUser>,
      config?: ClientConfig
    ) => {
      const fields = Object.keys(data).join(',');
      return client.put<IUser, string>(
        `${userPath}/${userID}?fields=${fields}`,
        data,
        config
      );
    },

    editPasswordById: (
      userID: string | number,
      data: {password: string; password_hash?: boolean},
      config?: ClientConfig
    ) => {
      return client.put<IUser, string>(
        `${userPath}/${userID}?fields=${
          data.password_hash ? 'password_hash' : 'password'
        }`,
        {password: data.password},
        config
      );
    },

    getUserApparal: (userID: string, config?: ClientConfig) => {
      return client.get<number[], string>(
        `/b/api/v1/users/${userID}/apparel?include_retired=true`,
        config
      );
    },

    fixApparelDistance: (params: IUserIdentifier, config?: ClientConfig) => {
      // returns array of apparel IDs that were updated
      return client.patch<number[], string>(
        `${userPath}/${params.userID}/apparel/distancefix`,
        config
      );
    },

    updatePreferences: (
      params: Partial<IAdminUpdatePrefs>,
      config?: ClientConfig
    ) => {
      const {userID, ...rest} = params;

      // must be a minium of one field or else there is no point in even calling this function
      let urlParams = 'fields=';
      typedObjectKeys(rest).forEach((key) => {
        urlParams += `${key},`;
      });

      // take off trailing ","
      urlParams = urlParams.substring(0, urlParams.length - 1);

      return client.put<IUser, string>(
        `${userPath}/${userID}/pref?${urlParams}`,
        rest,
        config
      );
    },

    getToken: (params: IUserIdentifier, config?: ClientConfig) => {
      // returns user JWT
      return client.get<string, string>(
        `${userPath}/${params.userID}/token`,
        config
      );
    },

    deleteById: (params: IUserIdentifier, config?: ClientConfig) => {
      return client.delete<IUser, string>(
        `${userPath}/${params.userID}`,
        config
      );
    },

    getActivityCalendar: (
      params: IGetActivityCalendarParameters,
      config?: ClientConfig
    ) => {
      const {user_name, startDate, endDate, ...rest} = params;

      const startStr = format(startDate, 'MM-dd-yyyy');
      const endStr = format(endDate, 'MM-dd-yyyy');
      let urlParams = `srtDate=${startStr}&endDate=${endStr}`;

      typedObjectKeys(rest).forEach((key) => {
        urlParams += `&${key}=${rest[key]}`;
      });

      return client.get<IActivityCalendar, string>(
        `${userPath}/${user_name}/activities/calendar?${urlParams}`,
        config
      );
    },

    getOfflineFiles: (
      params: IOfflineFilesListParams,
      config?: ClientConfig
    ) => {
      const {user_name, limit, dateRange} = params;
      return client.get<IOfflineFileDownload[], string>(
        `${userPath}/${user_name}/offline?${limit ? `limit=${limit}` : ''}&${
          dateRange
            ? `startDate=${format(
                dateRange.startDate,
                'MM-dd-yyyy'
              )}&endDate=${format(dateRange.endDate, 'MM-dd-yyyy')}`
            : ''
        }`,
        config
      );
    },

    getCPHistory: (username: string, config?: ClientConfig) => {
      return client.get<CriticalPowerHistory, string>(
        `${userPath}/${username}/cp/history`,
        config
      );
    },

    getCalendar: (
      userID: string,
      params: IUserCalendarClientParams,
      configs?: ClientConfig
    ) => {
      const requestParams: IUserCalendarRequestParams = {
        from: params.from ? getUnixTime(params.from) : undefined,
        to: params.to ? getUnixTime(params.to) : undefined,
        updated_after: params.updated_after
          ? getUnixTime(params.updated_after)
          : undefined,
        limit: params.limit,
        fields: params.fields
          ? params.fields.map(calendarTypeToCode)
          : undefined,
        include_deleted: params.include_deleted,
      };

      const urlParams = toURLParams(requestParams);

      return client.get<IUserCalendarResponse, string>(
        `${userPath}/${userID}/calendar?${urlParams}`,
        configs
      );
    },

    getFeatureAccess: (userID: string, config?: ClientConfig) => {
      return client.get<FeatureAccess, string>(
        `${userPath}/${userID}/feature-access`,
        config
      );
    },

    grantFeatureAccess: (
      userID: string,
      options?: GrantFeatureAccessOptions,
      config?: ClientConfig
    ) => {
      const urlParams = options ? toURLParams(options) : '';

      return client.post<FeatureAccess, string>(
        `${userPath}/${userID}/feature-access/grant?${urlParams}`,
        config
      );
    },

    revokeFeatureAccess: (
      userID: string,
      options?: RevokeFeatureAccessOptions,
      config?: ClientConfig
    ) => {
      const urlParams = options ? toURLParams(options) : '';
      return client.post<FeatureAccess, string>(
        `${userPath}/${userID}/feature-access/revoke?${urlParams}`,
        config
      );
    },

    getPermissionGroups: (userID: string, configs?: ClientConfig) => {
      return client.get<UserPermissionGroup[] | null, string>(
        `${userPath}/${userID}/permission-groups`,
        configs
      );
    },

    grantPermissionGroup: (
      userID: string,
      permissionGroupName: string,
      configs?: ClientConfig
    ) => {
      return client.post<UserPermissionGroup[], string>(
        `${userPath}/${userID}/permission-groups/${permissionGroupName}`,
        configs
      );
    },

    revokePermissionGroup: (
      userID: string,
      permissionGroupName: string,
      configs?: ClientConfig
    ) => {
      return client.delete<UserPermissionGroup[] | null, string>(
        `${userPath}/${userID}/permission-groups/${permissionGroupName}`,
        configs
      );
    },
  };
};
