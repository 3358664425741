import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles';
import {ICollectionLinkFields} from 'src/utils/contentful';
import {
  getImageSourceFromAsset,
  getImageAltFromAsset,
} from 'src/utils/contentful';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {CtaLinkButton} from '../../CtaLinkButton';
import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const CollectionLinkTextContentContainer = styled.div`
  max-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-bottom: ${dimensions.defaultPadding};

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const CollectionLinkTextContent = styled.div`
  h2 {
    font-size: 3.75rem;
  }
  p {
    font-size: 1.5rem;
  }
`;

type CollectionLinkSectionProps =
  ContentfulTemplateComponentProps<ICollectionLinkFields>;

export const CollectionLinkSection: React.FC<CollectionLinkSectionProps> = (
  props
) => {
  const {
    entry: {fields},
  } = props;
  const {textContent, linkedCollection, backgroundImage} = fields;

  const linkedCollectionSlug = linkedCollection.fields.slug;
  const fontColor = backgroundImage
    ? customColors.backgroundImageText
    : undefined;

  return (
    <FullWidthSection>
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <ContentContainer>
        <CollectionLinkTextContentContainer>
          <CollectionLinkTextContent
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(textContent),
            }}
            style={{color: fontColor}}
          />
        </CollectionLinkTextContentContainer>
        <CtaLinkButton
          internalName={''}
          buttonText={''}
          pageLink={`/collections/${linkedCollectionSlug}`}
        >
          <FormattedMessage
            id="CollectionLink.defaultCta"
            defaultMessage="View {collectionName}"
            values={{collectionName: linkedCollection.fields.name}}
          />
        </CtaLinkButton>
      </ContentContainer>
    </FullWidthSection>
  );
};
