import styled from 'styled-components';

import {dimensions, colors} from '@stryd/react-ui';

import {FullWidthSection} from '../FullWidthSection';
import {Link} from '../Link';
import {TwoColumnInfoBox, TwoColumnInfoBoxProps} from '../TwoColumnInfoBox';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface LinkStyleProps {
  linkStyle?: React.CSSProperties;
  linkHoverStyle?: React.CSSProperties;
}

const LinkContainer = styled.div<LinkStyleProps>`
  margin-top: calc(3 * ${dimensions.defaultPadding});

  a {
    color: ${colors.themes.dark.textHighlight};
    text-decoration: underline;
    ${(props) => ({...props.linkStyle} as any)};

    &:hover {
      color: ${colors.themes.dark.accentPrimary};
      text-decoration: underline;
      ${(props) => ({...props.linkHoverStyle} as any)};
    }
  }
`;

type TwoColumnInfoBoxSectionProps = TwoColumnInfoBoxProps & {
  link?: {
    href: string;
    text: React.ReactNode;
    style?: React.CSSProperties;
    hoverStyle?: React.CSSProperties;
  };
};

export const TwoColumnInfoBoxSection: React.FC<TwoColumnInfoBoxSectionProps> = (
  props
) => {
  const {link, mainContent, secondaryContent} = props;

  return (
    <FullWidthSection>
      <SectionContainer>
        <TwoColumnInfoBox
          mainContent={mainContent}
          secondaryContent={secondaryContent}
        />
        {link ? (
          <LinkContainer
            linkStyle={link.style}
            linkHoverStyle={link.hoverStyle}
          >
            <Link href={link.href}>{link.text}</Link>
          </LinkContainer>
        ) : null}
      </SectionContainer>
    </FullWidthSection>
  );
};
