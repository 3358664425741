import {useState} from 'react';

import Image from 'next/image';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {BorderOnlyActionButton, dimensions} from '@stryd/react-ui';
import {colors} from '@stryd/react-ui';
import {isNumber} from '@stryd/util-lib';

import {regionConfigs} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {useCart} from 'src/features/cart/contexts';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {usePriceFormatters} from 'src/hooks/usePriceFormatters';
import {colors as customColors} from 'src/styles/colors';
import {
  IFeaturedMembershipSectionFields,
  IProduct,
  IProductVariant,
} from 'src/utils/contentful/content-models/generated/contentful';
import {getImageSourceFromAsset} from 'src/utils/contentful/content-models/util';

import {Link} from '../../Link';
import {TwoColumnInfoBoxSection} from '../../layout/TwoColumnInfoBoxSection';
import {ContentfulTemplateComponentProps} from '../types';

const ContentBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  min-height: 350px;
`;

const imageSize = '175px';

const ProductImageContainer = styled.div`
  position: relative;
  width: ${imageSize};
  height: ${imageSize};
  margin-bottom: ${dimensions.twicePadding};
`;

const AddToCartButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * + * {
    margin-top: ${dimensions.defaultPadding};
  }
`;

const ErrorMessage = styled.span`
  color: red;
  max-width: 200px;
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  height: 100%;
  text-align: center;
`;

const ProductTitle = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: ${customColors.asideTextColor};
`;

const ProductSubtitle = styled.p`
  font-size: 1.25rem;
  margin: 0;
  color: ${customColors.asideTextColorSecondary};
`;

const ProductInfoGroupContainer = styled.div`
  margin-bottom: ${dimensions.halfPadding};
`;

const OneTimePrice = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;

const RecurringPrice = styled.p`
  color: ${colors.themes.dark.accentPrimary};
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: ${dimensions.halfPadding};
`;

const LinkContainer = styled.div`
  font-size: 12px;
  color: ${colors.themes.dark.accentPrimary};
`;

interface AddToCartContentBoxProps {
  product: IProduct;
  productVariant: IProductVariant;
  image: {
    src: string;
    alt: string;
  };
}

const AddToCartContentBox: React.FC<AddToCartContentBoxProps> = (props) => {
  const {product, productVariant, image} = props;
  const [cart, cartDispatch] = useCart();
  const {region, locale} = usePageRegionData();
  const router = useRouter();
  const [maxQtyErr, setMaxQtyErr] = useState(false);

  const handleAddToCart = () => {
    const {maxCheckoutQuantity} = productVariant.fields;
    const itemInCart = cart.lineItems.find(
      (item) => item.selectedVariant.sys.id === productVariant.sys.id
    );

    if (
      itemInCart &&
      isNumber(maxCheckoutQuantity) &&
      itemInCart.quantity >= maxCheckoutQuantity
    ) {
      setMaxQtyErr(true);
      return;
    }

    cartDispatch({
      type: 'ADD_QTY_TO_CART',
      region,
      quantity: 1,
      item: {product, selectedVariant: productVariant},
    });

    router.push(`/${region}/${locale}/cart`);
  };

  return (
    <ContentBoxContainer>
      <ProductImageContainer>
        <Image priority={true} layout="fill" objectFit="contain" {...image} />
      </ProductImageContainer>
      <AddToCartButtonContainer>
        <BorderOnlyActionButton onClick={handleAddToCart}>
          <FormattedMessage
            id="actions.addToCart"
            defaultMessage="Add to Cart"
          />
        </BorderOnlyActionButton>
        {maxQtyErr && (
          <ErrorMessage role="alert">
            <FormattedMessage
              id="errors.addToCartQuantityError"
              defaultMessage="You cannot add more of this item to your cart."
            />
          </ErrorMessage>
        )}
      </AddToCartButtonContainer>
    </ContentBoxContainer>
  );
};

interface MembershipInfoContentBoxProps {
  productContent: IFeaturedMembershipSectionFields;
}

const MembershipInfoContentBox: React.FC<MembershipInfoContentBoxProps> = (
  props
) => {
  const {
    productContent: {
      productTitle,
      productSubtitle,
      featuredProductVariant,
      detailsLinkText,
      detailsLinkUrl,
    },
  } = props;

  const {region, locale} = usePageRegionData();
  const {formatMembershipRecurringPrice, formatMembershipSetupPrice} =
    usePriceFormatters({
      locale,
      includeCommitment: true,
    });

  const {currency} = regionConfigs[region];

  const {oneTimePricesTotal, recurringPricesTotals} = getVariantPricing({
    variant: featuredProductVariant,
    region,
    currency,
  });

  return (
    <ContentBoxContainer>
      <ProductInfoContainer>
        <ProductInfoGroupContainer>
          <ProductTitle>{productTitle}</ProductTitle>
          <ProductSubtitle>{productSubtitle}</ProductSubtitle>
        </ProductInfoGroupContainer>

        <ProductInfoGroupContainer>
          {recurringPricesTotals.map((priceTotal) => {
            return (
              <RecurringPrice
                key={`${priceTotal.unit_amount}-${priceTotal.interval}`}
              >
                {formatMembershipRecurringPrice(priceTotal)}
              </RecurringPrice>
            );
          })}

          <OneTimePrice>
            {formatMembershipSetupPrice(oneTimePricesTotal)}
          </OneTimePrice>
        </ProductInfoGroupContainer>

        {detailsLinkUrl ? (
          <LinkContainer>
            <Link style={{textDecoration: 'underline'}} href={detailsLinkUrl}>
              {detailsLinkText}
            </Link>
          </LinkContainer>
        ) : null}
      </ProductInfoContainer>
    </ContentBoxContainer>
  );
};

type FeaturedMembershipSectionProps =
  ContentfulTemplateComponentProps<IFeaturedMembershipSectionFields>;

export const FeaturedMembershipSection: React.FC<FeaturedMembershipSectionProps> =
  ({entry}) => {
    const {fields} = entry;
    const {
      product,
      featuredProductVariant,
      productImage,
      relatedInfoLinkUrl,
      relatedInfoLinkText,
    } = fields;

    const link = relatedInfoLinkUrl
      ? {
          href: relatedInfoLinkUrl,
          text: relatedInfoLinkText,
          style: {color: 'unset'},
          hoverStyle: {color: colors.themes.dark.textHighlight},
        }
      : undefined;

    return (
      <TwoColumnInfoBoxSection
        mainContent={<MembershipInfoContentBox productContent={fields} />}
        secondaryContent={
          <AddToCartContentBox
            product={product}
            productVariant={featuredProductVariant}
            image={{
              src: getImageSourceFromAsset(productImage),
              alt: productImage.fields.description || productImage.fields.title,
            }}
          />
        }
        link={link}
      />
    );
  };
