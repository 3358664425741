import {useState} from 'react';

import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {
  BorderOnlyActionButton,
  colors,
  dimensions,
  ErrorMessage,
} from '@stryd/react-ui';
import {isNumber, stripeAmountToCurrencyAmount} from '@stryd/util-lib';

import {regionConfigs} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {useCart} from 'src/features/cart/contexts';
import {CartActionItem} from 'src/features/cart/types';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {addToCartEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';
import {variantToItems} from 'src/utils/analytics/variantToItems';
import {
  getImageAltFromAsset,
  getImageSourceFromAsset,
  IProduct,
} from 'src/utils/contentful';

import {BackgroundNextImage} from './BackgroundNextImage';
import {InternalLink} from './InternalLink';
import {ProductVariantPrice} from './ProductVariantPrice';

const cardDimension = '18rem';
const buttonContainerHeight = '5rem';
const overlayHeight = `calc(${cardDimension} - ${buttonContainerHeight})`;

const GridItemTextContainer = styled.div`
  opacity: 0;
  visibility: none;
  transition: opacity 0.2s, visibility 0s;
  width: 100%;
  height: ${overlayHeight};
  padding: ${dimensions.defaultPadding};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  &:hover {
    visibility: visible;
    opacity: 0.9;
  }
`;

const ProductCard = styled.div`
  background-color: ${colors.themes.dark.backgroundInverted};
  min-width: ${cardDimension};
  min-height: ${cardDimension};
  border-radius: ${dimensions.defaultBorderRadius};
  margin-right: ${dimensions.gridInputMarginBottom};

  &:last-of-type {
    margin-right: 0;
  }
`;

const Title = styled.h4`
  text-align: center;
  max-width: 100%;
  color: ${colors.themes.dark.textInverted};
  margin-top: 2rem;
`;

const Subtitle = styled.p`
  margin-bottom: 0.75rem;
  text-align: center;
  max-width: 100%;
  color: ${colors.themes.dark.textInverted};
`;

const PhotoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${dimensions.defaultBorderRadius};
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  z-index: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${buttonContainerHeight};
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-bottom-left-radius: ${dimensions.defaultBorderRadius};
  border-bottom-right-radius: ${dimensions.defaultBorderRadius};
  z-index: 1;
`;

type SmallProductCardProps = {product: IProduct};

export const SmallProductCard: React.FC<SmallProductCardProps> = (props) => {
  const {product} = props;

  const {slug} = product.fields;
  const productVariant = product.fields.variants[0];
  const {previewImage} = productVariant.fields;
  const {region} = usePageRegionData();
  const [cart, dispatch] = useCart();
  const [maxQtyErr, setMaxQtyErr] = useState(false);
  const {currency} = regionConfigs[region];

  const pricing = getVariantPricing({
    variant: productVariant,
    region,
    currency,
  });

  const addToCart = () => {
    const item: CartActionItem = {
      product,
      selectedVariant: productVariant,
    };

    const {maxCheckoutQuantity} = productVariant.fields;
    const itemInCart = cart.lineItems.find(
      (item) => item.selectedVariant.sys.id === productVariant.sys.id
    );

    if (
      itemInCart &&
      isNumber(maxCheckoutQuantity) &&
      itemInCart.quantity >= maxCheckoutQuantity
    ) {
      setMaxQtyErr(true);
      return;
    }

    track(
      addToCartEvent({
        ecommerce: {
          currency: pricing.oneTimePricesTotal.currency,
          value: stripeAmountToCurrencyAmount(
            pricing.oneTimePricesTotal.unit_amount,
            pricing.oneTimePricesTotal.currency
          ),
          items: variantToItems(productVariant),
        },
      })
    );

    dispatch({
      type: 'ADD_QTY_TO_CART',
      item,
      region,
      quantity: 1,
    });

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ProductCard>
      <PhotoContainer>
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(previewImage),
            alt: getImageAltFromAsset(previewImage),
          }}
        />
        <ButtonContainer>
          {!maxQtyErr ? (
            <BorderOnlyActionButton onClick={() => addToCart()}>
              <FormattedMessage
                id="actions.addToCart"
                defaultMessage="Add to Cart"
              />
            </BorderOnlyActionButton>
          ) : (
            <ErrorMessage role="alert" style={{textAlign: 'center', margin: 0}}>
              <FormattedMessage
                id="errors.addToCartQuantityError"
                defaultMessage="You cannot add more of this item to your cart"
              />
            </ErrorMessage>
          )}
        </ButtonContainer>
        <InternalLink href={`/products/${slug}`}>
          <GridItemTextContainer>
            <Title>{product.fields.name}</Title>
            <Subtitle>
              <ProductVariantPrice
                variant={productVariant}
                intervalFormat={'short'}
              />
            </Subtitle>
          </GridItemTextContainer>
        </InternalLink>
      </PhotoContainer>
    </ProductCard>
  );
};
