import {ReactNode} from 'react';

import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES, Node} from '@contentful/rich-text-types';
import Image from 'next/image';
import styled from 'styled-components';

import {colors, dimensions} from '@stryd/react-ui';

import {
  getBackgroundColor,
  getFontColor,
  getImageAltFromAsset,
  getImageSourceFromAsset,
  IThreeColumnSectionFields,
} from 'src/utils/contentful';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    flex-direction: column;
    align-content: center;
  }
`;

const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 32%;

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    text-align: center;
    padding: 0rem;
    align-content: center;
    width: 65%;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 1rem 0rem;
  width: 3rem;
  height: 3rem;
  border-radius: ${dimensions.defaultBorderRadius};
  overflow: hidden;

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    margin: 2rem auto;
  }
`;

const InlineHyperlink = styled.a`
  color: ${colors.themes.dark.textHighlight};
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const renderOptions = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => {
      return (
        <InlineHyperlink
          href={node.data.uri}
          style={{
            textDecoration: 'bold',
          }}
          target={node.data.uri.includes('stryd.com') ? '_self' : '_blank'}
          rel={
            node.data.uri.includes('stryd.com')
              ? undefined
              : 'noopener noreferrer'
          }
        >
          {children}
        </InlineHyperlink>
      );
    },
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const {target} = node.data;
      return (
        <ImageWrapper>
          <Image
            src={getImageSourceFromAsset(target)}
            alt={getImageAltFromAsset(target)}
            objectFit="cover"
            width="inherit"
            height="inherit"
          />
        </ImageWrapper>
      );
    },
  },
};

type ThreeColumnSectionProps =
  ContentfulTemplateComponentProps<IThreeColumnSectionFields>;

export const ThreeColumnSection: React.FC<ThreeColumnSectionProps> = (
  props
) => {
  const {
    entry: {fields},
  } = props;
  const {contentChunks, background = 'light', backgroundImage} = fields;

  const backgroundColor = getBackgroundColor(background, backgroundImage);
  const fontColor = getFontColor(background, backgroundImage);

  return (
    <FullWidthSection style={{backgroundColor, color: fontColor}}>
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <Container>
        {contentChunks.map((contentChunk, i) => {
          return (
            <Content key={`${contentChunk.sys.id}-${i}`}>
              {documentToReactComponents(
                contentChunk.fields.content,
                renderOptions
              )}
            </Content>
          );
        })}
      </Container>
    </FullWidthSection>
  );
};
