import {IFormParams} from '@stryd/models';
import {typedObjectKeys} from '@stryd/util-lib';

export const convertToFormData = (fileOpts: Partial<IFormParams>) => {
  const formData = new FormData();
  const {file, ...rest} = fileOpts;

  if (file) {
    formData.append('file', file);
  }

  typedObjectKeys(rest).forEach((key) => {
    const val = fileOpts[key];
    const strValue = typeof val === 'string' ? val : JSON.stringify(val);
    formData.append(key as string, strValue);
  });

  return formData;
};
