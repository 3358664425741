import Image from 'next/image';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {dimensions, colors} from '@stryd/react-ui';

import {
  languageNames,
  regionalConfigsArr,
  regionNames,
  SupportedLocale,
  ValidRegionCode,
} from 'src/config';
import {setCookie} from 'src/utils/cookies';

import {DefaultPagePadding} from '../layout';

const PageHeading = styled.h1`
  margin-bottom: 2.5rem;
  text-transform: uppercase;
`;

const RegionContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 24px 0;

  border-bottom: 1px solid gainsboro;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const RegionHeading = styled.h2`
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const LocaleContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: ${dimensions.twicePadding};

  > * {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const Locale = styled.li`
  margin: 0;
  font-size: 1rem;

  padding: 0 ${dimensions.defaultPadding};

  &:first-of-type {
    padding-left: 0;
  }
`;

const LocaleLink = styled.a`
  border-bottom: 1px dotted;

  &:hover {
    color: ${colors.themes.dark.textHighlight};
  }
`;

const FlagImage = styled(Image)<{hasBorder?: boolean}>`
  border: ${(props) =>
    props.hasBorder
      ? '1px solid #E8E8E8 !important'
      : undefined}; /* important because next applies a 'no border' style directly to the image */
`;

export const SelectStorePageTemplate = () => {
  const router = useRouter();

  const setPreferredRegion = (region: ValidRegionCode) => {
    setCookie({key: 'user_pref_region', value: region}, {expires: 14});
  };

  const redirectToStore = (
    region: ValidRegionCode,
    locale: SupportedLocale
  ): void => {
    const newPath = `/${region}/${locale}`;
    setPreferredRegion(region);
    router.push(newPath);
  };

  return (
    <DefaultPagePadding>
      <PageHeading>
        <FormattedMessage
          id="SelectStorePageTemplate.heading"
          defaultMessage="Select Your Store"
        />
      </PageHeading>

      {regionalConfigsArr.map(({regionCode, locales, flagIconSrc}) => {
        return (
          <RegionContainer key={`region-${regionCode}`}>
            <FlagImage
              src={flagIconSrc}
              alt={`Flag icon for ${regionNames[regionCode]}`}
              width="28"
              height="21"
              hasBorder={regionCode === 'jp'} // Just applying border to the japan flag for now because we don't have an asset with the border applied. In the future, better assets would be desireable.
            />
            <RegionHeading>{regionNames[regionCode]}</RegionHeading>

            <LocaleContainer>
              {locales.map((locale) => {
                return (
                  <Locale key={`${locale}-${regionCode}`}>
                    <LocaleLink
                      onClick={() => redirectToStore(regionCode, locale)}
                    >
                      {languageNames[locale]}
                    </LocaleLink>
                  </Locale>
                );
              })}
            </LocaleContainer>
          </RegionContainer>
        );
      })}
    </DefaultPagePadding>
  );
};
