import React from 'react';

// import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {NoSSR} from 'src/components/NoSSR';
import {
  RichText,
  FluidContainer,
  ContentContainer,
} from 'src/features/contentful/components';
import {useMediaQuery} from 'src/hooks/useMediaQuery';
import {colors as customColors} from 'src/styles';
import {IHeroSectionFields} from 'src/utils/contentful/content-models';
import {
  getButtonColor,
  getImageSourceFromAsset,
} from 'src/utils/contentful/content-models/util';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {BackgroundVideoClip} from '../../BackgroundVideoClip';
import {CtaButton} from '../../CtaButton';
import {ContentfulTemplateComponentProps} from '../types';

const {tabletPortrait} = dimensions.breakpoints;

const HeroSectionContainer = styled(FluidContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem 6rem;
  min-height: calc(100vh - ${dimensions.topNavHeight} - 135px);
  max-width: auto;
  padding: 4rem;

  @media screen and (min-width: ${tabletPortrait}) {
    padding: 10rem;
    min-height: calc(100vh - ${dimensions.topNavHeight});
  }
`;

const RichTextContainer = styled.div`
  h1 {
    font-size: 3.75rem;
  }

  p {
    font-size: 1.5rem;
  }

  @media screen and (min-width: ${tabletPortrait}) {
    h1 {
      font-size: 5.625rem;
    }
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-top: 3rem;

  > * + * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    width: 100%;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    flex-flow: column nowrap;
    align-items: center;
    > * + * {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }
`;

const getFontColor = (
  background: keyof (typeof customColors)['sectionBackground']
) => {
  switch (background) {
    case 'dark':
    case 'medium_dark':
      return 'white';
    case 'light':
    case 'medium_light':
      return 'inherit';
    default:
      return 'inherit';
  }
};

type HeroSectionProps = ContentfulTemplateComponentProps<IHeroSectionFields>;

export const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const {
    entry: {
      fields: {
        textContent,
        background = 'light',
        landscapeBackgroundVideo,
        portraitBackgroundVideo,
        landscapeBackgroundImage,
        portraitBackgroundImage,
        ctas,
        css,
      },
    },
  } = props;

  const isPortrait = useMediaQuery(`(max-width: ${tabletPortrait})`);

  const providedLandscapeBackgroundVideo =
    landscapeBackgroundVideo || portraitBackgroundVideo || null;
  const providedPortraitBackgroundVideo =
    portraitBackgroundVideo || landscapeBackgroundVideo || null;
  const providedLandscapeBackgroundImage =
    landscapeBackgroundImage || portraitBackgroundImage || null;
  const providedPortraitBackgroundImage =
    portraitBackgroundImage || landscapeBackgroundImage || null;

  const hasImgOrVideo =
    providedLandscapeBackgroundVideo ||
    providedPortraitBackgroundVideo ||
    providedLandscapeBackgroundImage ||
    providedPortraitBackgroundImage;
  const backgroundColor = customColors.sectionBackground[background];

  const renderBackground = (): React.ReactNode => {
    if (!isPortrait && providedLandscapeBackgroundVideo) {
      return (
        <NoSSR>
          <BackgroundVideoClip
            videoElementProps={{style: {backgroundColor}}}
            src={providedLandscapeBackgroundVideo.fields.file.url as string}
          />
        </NoSSR>
      );
    }
    if (isPortrait && providedPortraitBackgroundVideo) {
      return (
        <NoSSR>
          <BackgroundVideoClip
            videoElementProps={{style: {backgroundColor}}}
            src={providedPortraitBackgroundVideo.fields.file.url as string}
          />
        </NoSSR>
      );
    }
    if (!isPortrait && providedLandscapeBackgroundImage) {
      return (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(providedLandscapeBackgroundImage),
            alt: providedLandscapeBackgroundImage.fields.description,
            priority: true,
          }}
          containerProps={{
            style: {
              backgroundColor,
            },
          }}
        />
      );
    }
    if (isPortrait && providedPortraitBackgroundImage) {
      return (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(providedPortraitBackgroundImage),
            alt: providedPortraitBackgroundImage.fields.description,
            priority: true,
          }}
          containerProps={{
            style: {
              backgroundColor,
            },
          }}
        />
      );
    }

    return null;
  };

  const ctaButtons = ctas
    ? ctas.map((cta) => {
        return (
          <CtaButton
            key={cta.sys.id}
            cta={cta}
            color={getButtonColor(cta.fields.pageLink)}
          />
        );
      })
    : null;

  return (
    <HeroSectionContainer
      style={{
        color: hasImgOrVideo ? 'white' : getFontColor(background),
      }}
    >
      {renderBackground()}
      <ContentContainer>
        <RichTextContainer>
          <RichText content={textContent} overrideCSS={css} />
        </RichTextContainer>
        {ctas && <CTAContainer>{ctaButtons}</CTAContainer>}
      </ContentContainer>
    </HeroSectionContainer>
  );
};
