import React from 'react';

import {ExperimentVariant, useExperiment} from './useExperiment';

interface ExperimentVariationProps {
  variationId: string;
  weight: number;
}

export const ExperimentVariation: React.FC<ExperimentVariationProps> = (
  props
) => <>{props.children}</>;

const isVariantChild = (
  child: React.ReactNode
): child is React.ReactElement<ExperimentVariationProps> => {
  return (
    React.isValidElement(child) && typeof child.props.variationId === 'string'
  );
};

export const Experiment: React.FC<{id: string}> = (props) => {
  const variantChildren = React.Children.toArray(props.children).filter(
    isVariantChild
  );

  const variations: ExperimentVariant[] = variantChildren.map((variant) => ({
    id: variant.props.variationId,
    weight: variant.props.weight,
  }));

  const {selectedVariant} = useExperiment({
    experimentId: props.id,
    variations,
  });

  const mostProbableVariant = [...variations].sort(
    (a, b) => a.weight - b.weight
  )[variations.length - 1];

  const displayedChild = variantChildren.find((child) => {
    const idToDisplay = selectedVariant
      ? selectedVariant.id
      : mostProbableVariant.id;
    return child.props.variationId === idToDisplay;
  });

  return selectedVariant ? (
    <>{displayedChild}</>
  ) : (
    <div style={{visibility: 'hidden', display: 'contents'}}>
      {displayedChild}
    </div>
  );
};
