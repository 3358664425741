import {IPageLinkCta} from 'src/utils/contentful';

import {CtaLinkButton} from './CtaLinkButton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyCta = React.ComponentType<any>;

type CTA_TYPE = 'pageLinkCta';

type CtaTemplateDict = {
  [K in CTA_TYPE]: AnyCta;
};

interface CtaButtonProps {
  cta: IPageLinkCta;
  color?: string;
}

const ctaTypeTemplateMap: CtaTemplateDict = {
  pageLinkCta: CtaLinkButton,
};

const isCtaContentType = (
  ctaType: string
): ctaType is keyof typeof ctaTypeTemplateMap => {
  return !!ctaType && ctaType in ctaTypeTemplateMap;
};

const getCta = (contentType: string) => {
  if (!isCtaContentType(contentType)) {
    return null;
  }

  return ctaTypeTemplateMap[contentType];
};

export const CtaButton: React.FC<CtaButtonProps> = ({cta, color}) => {
  const contentType = cta.sys.contentType.sys.id;

  const CTA = getCta(contentType);

  if (!CTA) {
    return null;
  }

  return <CTA color={color} {...cta.fields} />;
};
