import React from 'react';

import Image from 'next/image';

import {ICardScrollSectionsFields} from 'src/utils/contentful/content-models';

import {ContentfulTemplateComponentProps} from '../../types';

import {Carousel, Card} from '@/components/ui/apple-cards-carousel';

type CardScrollSectionsProps =
  ContentfulTemplateComponentProps<ICardScrollSectionsFields>;

export const CardScrollSection: React.FC<CardScrollSectionsProps> = (props) => {
  const {
    entry: {
      fields: {entry, cards},
    },
  } = props;

  const cards2 =
    cards?.map((card, index) => (
      <Card
        key={index}
        card={{
          title: card.fields.header ?? '',
          category: card.fields.subheader ?? '',
          body: card.fields.field ?? '',
          src: card.fields.image
            ? convertToAbsoluteUrl(card.fields.image.fields.file.url)
            : '',
        }}
        index={index}
        layout={true}
      />
    )) ?? [];

  const Mobile = () => (
    <div className="ml-8 lg:ml-28">
      <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold text-neutral-800 dark:text-neutral-200 ">
        Stryd gives your effort
        <br />
        <span className="italic">a purpose</span>
      </h2>
      <p className="mt-8 font-medium text-lg">
        Dive into the in-depth features of the Stryd mobile app for
        <br />
        breakthrough insight that makes you eager to analyze your data everyday
      </p>
    </div>
  );

  const Watch = () => (
    <div className="ml-8 lg:ml-28">
      <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold text-neutral-800 dark:text-neutral-200 ">
        Pair with your sportswatch <br />
        <span className="italic">for real time guidance</span>
      </h2>
      <p className="mt-8 font-medium text-lg">
        Stryd pairs with your watch or phone to give you power-based guidance on
        every run.
      </p>
    </div>
  );

  const Title = () => (
    <div className="ml-8 lg:ml-28">
      <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold text-neutral-800 dark:text-neutral-200">
        Stryd gives your effort <br />
        <span className="italic">a purpose</span>
      </h2>
      <p className="mt-8 font-medium text-lg">
        Dive into the in-depth features of the Stryd mobile app for
        <br />
        breakthrough insight that makes you eager to analyze your data everyday
      </p>
    </div>
  );

  return (
    <div className="w-full h-full pt-20">
      {entry === 'Mobile' ? (
        <Mobile />
      ) : entry === 'Watch' ? (
        <Watch />
      ) : (
        <Title />
      )}
      <Carousel items={cards2} />
    </div>
  );
};

function convertToAbsoluteUrl(url: string): string {
  // Check if the URL starts with '//', indicating a protocol-relative URL
  if (url.startsWith('//')) {
    // Prepend 'https:' to the URL to make it an absolute URL
    return `https:${url}`;
  }
  // If the URL is already absolute, return it as is
  return url;
}
