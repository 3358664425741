import {HttpClient, ClientConfig} from '../../http';
import {
  IActivity,
  IActivitySummary,
  IActivityStaticMapData,
  IFitDownloadResponse,
  IPairWorkoutParams,
  IPairEventParams,
  IActivityMergeParams,
  IAddActivity,
  IAddActivityResponse,
  ICropActivityParams,
  ICropActivityResponse,
  ActivityAudioNote,
} from '@stryd/models';
import {ActivityMapRequestOpts} from '@stryd/models';
import {IStrydApiErr} from '../../types';
import {convertToFormData} from '../utility';

const backendPath = `/b/api/v1`;
const activityPath = backendPath + `/activities`;

export const setupActivityEndpoints = (client: HttpClient) => {
  return {
    getById: (id: string, config?: ClientConfig) => {
      return client.get<IActivity, IStrydApiErr>(
        `${activityPath}/${id}`,
        config
      );
    },

    editById: (
      id: string,
      data: {[P in keyof IActivity]?: any},
      config?: ClientConfig
    ) => {
      // server response with "Success" if successful edit
      return client.put<string, {message: string}>(
        `${activityPath}/${id}`,
        data,
        config
      );
    },

    deleteById: (id: string | number, config?: ClientConfig) => {
      return client.delete<void, string>(`${activityPath}/${id}`, config);
    },

    upload: (userId: string, data: IAddActivity, config?: ClientConfig) => {
      const formData = convertToFormData(data);
      return client.post<IAddActivityResponse, string>(
        `${backendPath}/users/${userId}/activities`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        }
      );
    },

    export: (
      userId: string,
      data: {
        from?: number;
        to?: number;
        activity_ids?: number[];
        file_format?: 'csv' | 'fit';
      },
      config?: ClientConfig
    ) => {
      return client.post<string, {message: string}>(
        `${backendPath}/users/${userId}/activities/export`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        }
      );
    },

    /**
     * @deprecated use downloadFitNew()
     */
    downloadFit: (activityId: string | number, config?: ClientConfig) => {
      return client.post<IFitDownloadResponse, string>(
        `${activityPath}/${activityId}/fit`,
        config
      );
    },

    downloadFitNew: (
      {userId, activityId}: {userId: string; activityId: string | number},
      config?: ClientConfig
    ) => {
      return client.get<IFitDownloadResponse, string>(
        `${backendPath}/users/${userId}/activities/${activityId}/fit`,
        config
      );
    },

    downloadCSV: (
      {userId, activityId}: {userId: string; activityId: string | number},
      config?: ClientConfig
    ) => {
      return client.get<string, string>(
        `${backendPath}/users/${userId}/activities/${activityId}/csv`,
        config
      );
    },

    /** Requests a static image for an activity, using default Stryd map styling */
    getMapImage: (
      {
        activityId,
        userId,
        resolution,
        styleId = 'ck0y01xax16601cocay7e2057',
      }: ActivityMapRequestOpts,
      config?: ClientConfig
    ) => {
      return client.get<IActivityStaticMapData, string>(
        backendPath +
          `/users/${userId}/activities/${activityId}/map?theme=${styleId}&resolution=${resolution}`,
        config
      );
    },

    /**
     * Pairing a workout and activity will also cause a reschedule of the workout to the same day as the activity.
     * The workout will also be updated to include the activity_id of the paired activity
     */
    pairWorkout: (params: IPairWorkoutParams, configs?: ClientConfig) => {
      const {activityId, workoutId, workoutSource, workoutSourceId} = params;
      return client.patch<IActivitySummary, string>(
        `${activityPath}/${activityId}/pair/${workoutId}?source=${workoutSource}&source_id=${workoutSourceId}`,
        configs
      );
    },

    /**
     * When unpairing, the backend will update both the workout and activity to indicate the unpair
     */
    unpairWorkout: (params: IPairWorkoutParams, configs?: ClientConfig) => {
      const {activityId, workoutSource, workoutSourceId} = params;
      // we just need to pass a workout id of 0 for the unpair to take effect.
      // backend handles the rest.
      return client.patch<IActivitySummary, {message: string}>(
        `${activityPath}/${activityId}/pair/0?source=${workoutSource}&source_id=${workoutSourceId}`,
        configs
      );
    },

    pairEvent: (params: IPairEventParams, configs?: ClientConfig) => {
      const {activityId, eventId, userId} = params;
      return client.post<IActivitySummary, string>(
        `${backendPath}/users/${userId}/activities/${activityId}/pair/event/${eventId}`,
        configs
      );
    },

    unpairEvent: (params: IPairEventParams, configs?: ClientConfig) => {
      const {activityId, eventId, userId} = params;
      // we just need to pass a event id of 0 for the unpair to take effect.
      // backend handles the rest.
      return client.delete<IActivitySummary, string>(
        `${backendPath}/users/${userId}/activities/${activityId}/pair/event/${eventId}`,
        configs
      );
    },

    recalculate: (
      params: {user_id: string; startDate: number; endDate: number},
      config?: ClientConfig
    ) => {
      const {user_id, startDate, endDate} = params;
      // server response with "Done!" if successful edit

      return client.put<string, string>(
        `${backendPath}/users/${user_id}/activities/recalculate?from=${startDate}&to=${endDate}`,
        config
      );
    },

    // returns the watch ID
    merge: (params: IActivityMergeParams, configs?: ClientConfig) => {
      const {userId, watchId, offlineId} = params;

      return client.put<number, string>(
        `${backendPath}/users/${userId}/activities/merge?watch=${watchId}&offline=${offlineId}`,
        configs
      );
    },

    crop: (
      params: ICropActivityParams,
      rangeData: number[][],
      configs?: ClientConfig
    ) => {
      const {userId, activityId} = params;

      return client.put<ICropActivityResponse, {message: string}>(
        `${backendPath}/users/${userId}/activities/${activityId}/crop`,
        {range: rangeData},
        configs
      );
    },

    getFootpathUrl: (id: string, userId: string, config?: ClientConfig) => {
      return client.get<{foot_data_url: string}, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${id}/footdata`,
        config
      );
    },

    getNotes: ({
      activityId,
      userId,
      config,
    }: {
      activityId: string;
      userId: string;
      config?: ClientConfig;
    }) => {
      return client.get<ActivityAudioNote[], IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes`,
        config
      );
    },

    getNoteDownloadUrl: ({
      activityId,
      userId,
      noteId,
      config,
    }: {
      activityId: string;
      userId: string;
      noteId: string;
      config?: ClientConfig;
    }) => {
      return client.get<{file_url: string}, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes/${noteId}/download-url`,
        config
      );
    },

    createNote: ({
      activityId,
      userId,
      text,
      config,
    }: {
      activityId: string;
      userId: string;
      text: string;
      config?: ClientConfig;
    }) => {
      return client.post<ActivityAudioNote, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes`,
        {text},
        config
      );
    },

    uploadAudioNote: ({
      activityId,
      userId,
      second,
      file,
      config,
    }: {
      activityId: string;
      userId: string;
      second: number;
      file: File;
      config?: ClientConfig;
    }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('second', JSON.stringify(second));

      return client.post<ActivityAudioNote, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes/audio`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        }
      );
    },

    updateNote: ({
      activityId,
      userId,
      noteId,
      text,
      config,
    }: {
      activityId: string;
      userId: string;
      noteId: string;
      text: string;
      config?: ClientConfig;
    }) => {
      return client.patch<ActivityAudioNote, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes/${noteId}`,
        {text},
        config
      );
    },

    deleteNote: ({
      activityId,
      userId,
      noteId,
      config,
    }: {
      activityId: string;
      userId: string;
      noteId: string;
      config?: ClientConfig;
    }) => {
      return client.delete<ActivityAudioNote, IStrydApiErr>(
        `${backendPath}/users/${userId}/activities/${activityId}/notes/${noteId}`,
        config
      );
    },

    getFootpathDistributions: (config?: ClientConfig) => {
      return client.get<unknown, string>(
        `${backendPath}/stats/distributions`,
        config
      );
    },
  };
};
