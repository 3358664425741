import {
  StripeOrder,
  StripeOrderIdQueryOption,
  StripeOrderFormat,
  UNSAFE_STRIPE,
} from '@stryd/models';
import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';

const orderPath = `/canyon/orders`;

type OrderResponse<Query> = Query extends {format: 'purchase'}
  ? UNSAFE_STRIPE.StripePurchase
  : StripeOrder;

export const setupOrderEndpoints = (client: HttpClient) => {
  return {
    /** Fetch an order, either by the checkout session id, datastore id, or pretty id */
    getById: <Query extends StripeOrderIdQueryOption & StripeOrderFormat>(
      rawQuery: Query,
      config?: ClientConfig
    ) => {
      const query = '?' + toURLParams(rawQuery);
      return client.get<OrderResponse<Query>, string>(
        orderPath + query,
        config
      );
    },

    updatePhoneNumber: (
      order: {pretty_id: string; phone_number: string},
      config?: ClientConfig
    ) => {
      const path = orderPath + '/' + order.pretty_id;
      return client.patch<StripeOrder, string>(
        path,
        {phone_number: order.phone_number},
        config
      );
    },
  };
};
