import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';
import {NewsletterSignupParams} from '@stryd/models';

export const setupEmailEndpoints = (client: HttpClient) => {
  const basePath = `/canyon/email/subscribe`;

  return {
    signupForList: (params: NewsletterSignupParams, configs?: ClientConfig) => {
      const {email, tags, list} = params;
      const joinedTags = tags ? tags.join(',') : '';
      const urlParams = toURLParams({list, tags: joinedTags, email});

      return client.post(`${basePath}?${urlParams}`, configs);
    },
  };
};
