import {useState, useEffect} from 'react';

import {addYears} from 'date-fns';
import equals from 'fast-deep-equal';
import Cookies from 'js-cookie';

import {usePrevious} from 'src/hooks/usePrevious';
import {viewExperimentVariationEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';

const sendExperimentAnalytics = (experimentId: string, variationId: string) => {
  track(
    viewExperimentVariationEvent({
      experimentId: experimentId,
      variation: variationId,
    })
  );
};

export const getVariationFromCookies = (
  experimentId: string
): string | undefined => {
  return Cookies.get(experimentId);
};

const addVariationToCookies = (experimentId: string, variationId: string) => {
  const expire = addYears(new Date(), 1);
  Cookies.set(experimentId, variationId, {
    expires: expire,
    path: '/',
  });
};

export interface ExperimentVariant {
  id: string;
  /** number > 0 and <= 1, represents the probability that this variation should be shown */
  weight: number;
}

interface UseExperimentParams<VariantType extends ExperimentVariant> {
  experimentId: string;
  variations: VariantType[];
  enabled?: boolean;
}

interface UseExperimentResult<VariantType extends ExperimentVariant> {
  selectedVariant: VariantType | null;
}

/**
 * Selects a variation from the provided list, with memory of the last selection stored in cookies.
 * Since this hook uses cookies to make a decision, it will return a selectedVariant === null until a decision is made in the browser.
 *
 * Selections are made and tracked any time that the provided `variations` changes compared to its previous version (deep equality check)
 */
export const useExperiment = <
  VariantType extends ExperimentVariant = ExperimentVariant
>({
  experimentId,
  variations,
  enabled = true,
}: UseExperimentParams<VariantType>): UseExperimentResult<VariantType> => {
  const [selectedVariant, setSelectedVariant] = useState<VariantType | null>(
    null
  );
  const prevVariations = usePrevious(variations);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (equals(variations, prevVariations)) {
      return;
    }

    const handleSelection = (variation: VariantType) => {
      setSelectedVariant(variation);
      addVariationToCookies(experimentId, variation.id);
      sendExperimentAnalytics(experimentId, variation.id);
    };

    const prevId = getVariationFromCookies(experimentId);

    if (prevId) {
      const prevSelection = variations.find((v) => v.id === prevId);

      if (prevSelection) {
        handleSelection(prevSelection);
        return;
      }
    }

    // stack the weights to a 0 - 1 scale so that we can use a random number
    // to pick a variant with the correct probability
    const variantsByWeight = [...variations]
      .filter((v) => v.weight > 0 && v.weight <= 1) // validate variation weights
      .sort((a, b) => a.weight - b.weight);

    if (variantsByWeight.length === 0) {
      console.warn(
        'Could not find any valid variations. Check that variations were provided, and weights are greater than 0 and less than or equal to 1. The selected variant will always be the first provided'
      );
      handleSelection(variations[0]);
      return;
    }

    let weightSum = 0;
    const variantsWithAccumulatedWeight: {
      id: string;
      threshold: number;
    }[] = [];
    for (const variant of variantsByWeight) {
      weightSum += variant.weight;
      variantsWithAccumulatedWeight.push({
        id: variant.id,
        threshold: weightSum,
      });
    }

    let selected =
      variantsWithAccumulatedWeight[variantsWithAccumulatedWeight.length - 1];
    const randomNumber = Math.random();

    for (const variant of variantsWithAccumulatedWeight) {
      if (randomNumber <= variant.threshold) {
        selected = variant;
        break;
      }
    }

    // allow non-null-assertion because we created our select from an entry of the original variations array,
    // so the ID should exist
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedOriginalVariant = variations.find(
      (v) => v.id === selected.id
    ) as VariantType;

    handleSelection(selectedOriginalVariant);
  }, [variations, prevVariations, experimentId, enabled]);

  return {selectedVariant};
};
