import {HttpClient, ClientConfig} from '../../http';
import {Product} from '@stryd/models';

const stripePath = `/canyon/admin/stripe`;

export const setupStripeEndpoints = (client: HttpClient) => {
  return {
    getStripeProducts: (config?: ClientConfig) => {
      return client.get<Product[] | null, string>(
        `${stripePath}/products`,
        config
      );
    },
  };
};
