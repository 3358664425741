import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {usePageRegionData} from 'src/contexts';
import {
  IProduct,
  IProductsCollectionSectionFields,
  getFontColor,
  getBackgroundColor,
  isAllowedRegion,
} from 'src/utils/contentful';

import {FullWidthSection} from '../../FullWidthSection';
import {SmallProductCard} from '../../SmallProductCard';
import {ContentfulTemplateComponentProps} from '../types';

type ProductsCollectionSectionProps =
  ContentfulTemplateComponentProps<IProductsCollectionSectionFields>;

const HeadingContainer = styled.div`
  h2,
  h3,
  p {
    margin: 0;
  }

  h2:last-of-type,
  h3:last-of-type,
  p:last-of-type {
    margin-bottom: ${dimensions.textMarginBottom};
  }
`;

const ProductsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

export const ProductsCollectionSection: React.FC<ProductsCollectionSectionProps> =
  (props) => {
    const {fields} = props.entry;
    const {heading, background, productCollection} = fields;
    const {region} = usePageRegionData();
    const products: IProduct[] = productCollection.fields.products;
    const regionalProducts: IProduct[] = products.filter(
      ({fields: productFields}) => {
        const isAvailableInRegion = isAllowedRegion(
          region,
          productFields.availableInRegions
        );
        return isAvailableInRegion && !productFields.hidden;
      }
    );

    if (!regionalProducts.length) {
      return <></>;
    }

    return (
      <FullWidthSection
        style={{
          backgroundColor: getBackgroundColor(background),
          color: getFontColor(background),
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <HeadingContainer
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(heading),
          }}
        />
        <ProductsSection>
          {regionalProducts.map((product, index) => {
            return (
              <SmallProductCard
                key={`${product.fields.slug}-${index}`}
                product={product}
              />
            );
          })}
        </ProductsSection>
      </FullWidthSection>
    );
  };
