import {usePageRegionData} from 'src/contexts';
import {IRegionalContentContainerFields} from 'src/utils/contentful/content-models/generated/contentful';

import {ContentfulTemplateComponentProps} from '../types';

type RegionalContentContainerProps =
  ContentfulTemplateComponentProps<IRegionalContentContainerFields>;

export const RegionalContentContainer: React.FC<RegionalContentContainerProps> =
  ({entry, contentfulTemplatePicker: ContentfulTemplatePicker}) => {
    const {region} = usePageRegionData();

    const components = entry.fields[region] ?? entry.fields.default;

    return (
      <>
        {components.map((component, index, entries) => {
          return (
            <ContentfulTemplatePicker
              key={`${component.sys.id}-${index}`}
              entry={component}
              entryIndex={index}
              entries={entries}
            />
          );
        })}
      </>
    );
  };
