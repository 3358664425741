import {useState} from 'react';

import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import Image from 'next/image';
import {useInterval} from 'react-use';
import styled from 'styled-components';

import {colors, dimensions} from '@stryd/react-ui';

import {BackgroundNextImage} from 'src/components/BackgroundNextImage';
import {CtaButton} from 'src/components/CtaButton';
import {ContentContainer} from 'src/features/contentful/components';
import {colors as customColors} from 'src/styles';
import {
  findLinkedSectionChainIndex,
  getBackgroundColor,
  getFontColor,
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
  getButtonColor,
  ILinkedContentSectionFields,
} from 'src/utils/contentful';

import {ContentfulTemplateComponentProps} from '../types';

/**
 * isEven is used in a chain of Linked Content Sections to alternate text & image content on left vs. right.
 */
interface ContainerProps {
  isEven?: boolean;
}

type LinkedContentSectionProps =
  ContentfulTemplateComponentProps<ILinkedContentSectionFields>;

const Container = styled(ContentContainer)<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 5rem 2rem;

  @media screen and (min-width: ${dimensions.breakpoints.tabletLandscape}) {
    flex-direction: ${(props) => (props.isEven ? 'row' : 'row-reverse')};
    padding: 8.75rem 10rem;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${dimensions.breakpoints.tabletLandscape}) {
    width: 60%;
    flex: 0 0 60%;
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: 2.75rem;
`;

const CTAContainer = styled.div`
  margin-top: 4rem;
`;

const AccentHeading = styled.p`
  font-size: 1.5rem;
  color: ${colors.themes.dark.accentPrimary};
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    font-size: 1.25rem;
  }
`;

const Heading = styled.h2`
  font-size: 2.25rem;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const Body = styled.p`
  font-size: 1.25rem;
  color: #545659;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    font-size: 1.125rem;
  }
`;

const ImageSection = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  margin-top: 4rem;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    max-width: 40%;
    margin-top: 0;
    ${(props) =>
      props.isEven ? 'padding-left: 20px;' : 'padding-right: 20px;'}
  }

  @media screen and (min-width: ${dimensions.breakpoints.tabletLandscape}) {
    ${(props) =>
      props.isEven ? 'padding-left: 60px;' : 'padding-right: 60px;'}
  }

  @media screen and (min-width: ${dimensions.breakpoints.desktopSmall}) {
    ${(props) =>
      props.isEven ? 'padding-left: 100px;' : 'padding-right: 100px;'}
  }
`;

const LinkedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkedContent = styled.div`
  margin-bottom: 2rem;
`;

const LinkedContentHeading = styled.span<{
  isCurrent?: boolean;
  clickable?: boolean;
}>`
  display: inline-block;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: ${(props) => (props.clickable ? 'underline' : undefined)};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'text')};
  color: ${(props) =>
    props.isCurrent ? customColors.topicHeaders[2] : undefined};
  margin-bottom: 0.25rem;

  &:hover {
    text-decoration: ${(props) => (props.clickable ? 'underline' : undefined)};
  }

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    text-align: center;
    margin: auto;
    font-size: 1rem;
  }
`;

const LinkedContentBody = styled.div`
  p {
    color: #545659;
    font-size: 1rem;
    margin-top: 3px;
    margin-bottom: 0;

    & + p {
      margin-top: ${dimensions.defaultPadding};
    }

    @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
      font-size: 0.875rem;
    }
  }
`;

export const LinkedContentSection: React.FC<LinkedContentSectionProps> = (
  props
) => {
  const {fields, sys} = props.entry;
  const {
    heading,
    accentHeading,
    body,
    callToAction,
    linkedContent,
    background,
    backgroundImage,
    clickable,
  } = fields;
  const [running, setRunning] = useState(true);
  const [currentLinkedContentIndex, setCurrentLinkedContentIndex] = useState(0);
  const currentLinkedContent = linkedContent[currentLinkedContentIndex];

  useInterval(
    () => {
      setCurrentLinkedContentIndex(
        (currentLinkedContentIndex + 1) % linkedContent.length
      );
    },
    clickable && running ? 6000 : null
  );

  const selfIndex = findLinkedSectionChainIndex(props.entries, sys.id);
  const backgroundColor = getBackgroundColor(
    background || (selfIndex % 2 === 0 ? 'light' : 'medium_light'),
    backgroundImage
  );
  const fontColor = getFontColor(
    background || (selfIndex % 2 === 0 ? 'light' : 'medium_light'),
    backgroundImage
  );

  return (
    <div
      style={{
        backgroundColor,
        color: fontColor,
      }}
    >
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <Container isEven={selfIndex % 2 === 0}>
        <TextSection>
          <HeadingContainer>
            <AccentHeading>{accentHeading}</AccentHeading>
            <Heading>{heading}</Heading>
            <Body>{body}</Body>
          </HeadingContainer>
          <LinkedContentContainer>
            {linkedContent.map((content, index) => (
              <LinkedContent key={index}>
                <LinkedContentHeading
                  clickable={clickable}
                  isCurrent={
                    clickable && currentLinkedContent.sys.id === content.sys.id
                  }
                  onClick={() => {
                    if (clickable) {
                      setCurrentLinkedContentIndex(index);
                      setRunning(false);
                    }
                  }}
                >
                  {content.fields.linkedHeading}
                </LinkedContentHeading>
                {content.fields.body ? (
                  <LinkedContentBody
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(content.fields.body),
                    }}
                  />
                ) : null}
              </LinkedContent>
            ))}
          </LinkedContentContainer>
          <CTAContainer>
            {callToAction && (
              <CtaButton
                cta={callToAction}
                color={getButtonColor(callToAction.fields.pageLink)}
              />
            )}
          </CTAContainer>
        </TextSection>
        {currentLinkedContent.fields.linkedMedia && (
          <ImageSection isEven={selfIndex % 2 === 0}>
            <Image
              src={getImageSourceFromAsset(
                currentLinkedContent.fields.linkedMedia
              )}
              alt={currentLinkedContent.fields.linkedMedia.fields.title}
              height={getImageHeightFromAsset(
                currentLinkedContent.fields.linkedMedia
              )}
              width={getImageWidthFromAsset(
                currentLinkedContent.fields.linkedMedia
              )}
            />
          </ImageSection>
        )}
      </Container>
    </div>
  );
};
