import {HttpClient, ClientConfig} from '../../http';

import {ICoach} from '@stryd/models';

const coachesPath = `/b/coaches`;

export const setupCoachEndpoints = (client: HttpClient) => {
  return {
    getCoaches: (config?: ClientConfig) => {
      return client.get<ICoach[], string>(`${coachesPath}`, config);
    },
  };
};
