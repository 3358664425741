import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {Document, Node} from '@contentful/rich-text-types';
import {BLOCKS} from '@contentful/rich-text-types';
import Image from 'next/image';
import styled from 'styled-components';

import {largeH2} from 'src/styles';
import {
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
} from 'src/utils/contentful';

const TextBlock = styled.div`
  font-size: 1.5rem;
  text-align: center;

  h2 {
    ${largeH2};
    margin-top: 2rem;
  }
`;

const ImageWrapper = styled.div`
  margin: 4rem 0;
`;

interface BigCenteredContentProps {
  richText: Document;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

/* See https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/ for other renderOptions for videos, links, etc. */
const renderOptions = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
      const {target} = node.data;
      return (
        <ImageWrapper>
          <Image
            src={getImageSourceFromAsset(target)}
            alt={getImageAltFromAsset(target)}
            width={getImageWidthFromAsset(target)}
            height={getImageHeightFromAsset(target)}
          />
        </ImageWrapper>
      );
    },
  },
};

export const BigCenteredContent: React.FC<BigCenteredContentProps> = ({
  richText,
  containerProps,
}) => {
  return (
    <TextBlock {...containerProps}>
      {documentToReactComponents(richText, renderOptions)}
    </TextBlock>
  );
};
