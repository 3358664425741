import {
  IPlatformStatus,
  IConnectionResponse,
  ISuuntoAuthResponse,
  PlatformApiKey,
  IActivityFileMetaData,
  IFileUploadParams,
  IActivityUploadSuccess,
  IActivityUploadErr,
} from '@stryd/models';
import {HttpClient, ClientConfig} from '../../http';

export const setupPlatformEndpoints = (client: HttpClient) => {
  return {
    setPlatformConnection: (
      platform: PlatformApiKey,
      connect: boolean,
      configs?: ClientConfig
    ) => {
      if (connect) {
        return client.get<IConnectionResponse, string>(
          `/b/platform/oauth/${platform}`,
          configs
        );
      }

      return client.delete<string, string>(`/b/platform/oauth/${platform}`);
    },

    getConnectedPlatforms: (configs?: ClientConfig) => {
      return client.get<IPlatformStatus[], string>(
        `/b/internal/platforms`,
        configs
      );
    },

    /**
     * The returned `payload` field in response body will be an empty string if the user
     * does not have an active suunto connection.
     */
    getSuuntoStatus: (configs?: ClientConfig) => {
      return client.post<ISuuntoAuthResponse, string>(
        `/b/platform/auth/suunto/check`,
        configs
      );
    },

    /**
     * initSuuntoAuth will return a `payload` of "" because there is not an active connection.
     * If the response is 200 status, you can then open 'http://www.movescount.com/settings#connections'
     * in a new window to allow the user to sing in to Movescount.
     */
    initSuuntoAuth: (email: string, configs?: ClientConfig) => {
      return client.post<ISuuntoAuthResponse, string>(
        `/b/platform/auth/suunto`,
        {email},
        configs
      );
    },

    /**
     * Upload an activity file in FIT or TCX format.
     *
     */
    uploadActivityFile: (
      {file, userToken}: IFileUploadParams,
      configs: ClientConfig = {}
    ) => {
      const uploadMetaData: IActivityFileMetaData = {
        sizeInBytes: file.size,
        md5: '',
        oauthToken: userToken,
        privacy: 'public',
        activityIds: [1],
      };

      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadMetaData', JSON.stringify(uploadMetaData));

      const reqConfig: ClientConfig = {
        ...configs,
        headers: {...configs.headers, 'Content-Type': 'multipart/form-data'},
      };

      return client.post<IActivityUploadSuccess, IActivityUploadErr>(
        `/b/platform/data/stryd`,
        formData,
        reqConfig
      );
    },
  };
};
