import React from 'react';

import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import {Asset} from 'contentful';
import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {colors as customColors} from 'src/styles/colors';
import {
  getImageSourceFromAsset,
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getImageWidthFromAsset,
} from 'src/utils/contentful';
import {IPodcastLinksSectionFields} from 'src/utils/contentful/content-models';

import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const SectionColumn = styled(FullWidthSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextBlock = styled.div`
  width: 100%;
  font-size: 1.5rem;
  text-align: center;

  h2 {
    font-size: 3rem;

    @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
      font-size: 2rem;
    }

    @media screen and (max-width: ${dimensions.breakpoints.mobileSmall}) {
      font-size: 1.75rem;
    }
  }

  p {
    margin: auto;
    max-width: 875px;
  }
`;

const ListeningAppsContainer = styled.div`
  margin-top: 4rem;
  max-width: 875px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

const ListeningApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 1fr;
  min-width: 100px;
  max-width: 150px;

  margin: 1rem;
  margin-bottom: 1.5rem;
`;

const ListeningAppIconContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 80%;

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    max-width: 125px;
  }
`;

const ListeningAppLink = styled.a`
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: ${customColors.border};
  }
`;

const ListeningAppHeading = styled.div`
  font-size: 1rem;
  text-align: center;
  padding-bottom: 0.5rem;
`;

type PodcastLinksProps =
  ContentfulTemplateComponentProps<IPodcastLinksSectionFields>;

export const PodcastLinksSection: React.FC<PodcastLinksProps> = ({entry}) => {
  const {
    fields: {leadText, featureDescriptions},
  } = entry;

  const ContentfulAssetImage = (icon: Asset): JSX.Element => {
    return (
      <Image
        src={getImageSourceFromAsset(icon)}
        alt={getImageAltFromAsset(icon)}
        width={getImageWidthFromAsset(icon)}
        height={getImageHeightFromAsset(icon)}
      />
    );
  };

  return (
    <SectionColumn>
      {leadText && (
        <TextBlock
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(leadText),
          }}
        />
      )}

      {featureDescriptions && (
        <ListeningAppsContainer>
          {featureDescriptions.map(
            ({sys, fields: {heading, icon, externalLink}}) => {
              return (
                <ListeningApp key={sys.id}>
                  {externalLink ? (
                    <ListeningAppLink href={externalLink} target="_blank">
                      <ListeningAppIconContainer>
                        {icon && ContentfulAssetImage(icon)}
                      </ListeningAppIconContainer>
                      <ListeningAppHeading>{heading}</ListeningAppHeading>
                    </ListeningAppLink>
                  ) : (
                    <>
                      <ListeningAppIconContainer>
                        {icon && ContentfulAssetImage(icon)}
                      </ListeningAppIconContainer>
                      <ListeningAppHeading>{heading}</ListeningAppHeading>
                    </>
                  )}
                </ListeningApp>
              );
            }
          )}
        </ListeningAppsContainer>
      )}
    </SectionColumn>
  );
};
