/* eslint-disable */
// @ts-nocheck
import createClient, {ClientConfig, createCancelController} from './http';
import {setupEndpoints} from './endpoints';
import {apiBaseURL} from './constants';
import {ServiceHttpClients} from './types';

const defaultConfig: ClientConfig = {
  baseURL: apiBaseURL,
};

/**
 * create a Stryd API client with built in routes.
 * To add a JWT bearer token to the client's headers, use the exposed `setBearerToken` method.
 * @param config optionally pass in any base configuration for the client (e.g. a new base URL)
 * @returns all api endpoints, a method to set a token, as well as exposure to the underlying `http` client.
 *
 *
 * Example client usage:
 * ```
 * const api = create();
 *
 * const res = await api.user.getById('some-id');
 *
 * if (res.ok) {
 *   // server responded with 200 status
 *   console.log(res.data); // res.data is of type IUser
 * } else if (res.err.response) {
 *   // server responded with some error status code and possibly data
 *   console.log(res.err.response.status, res.err.response.data)
 * } else {
 *   // did not get a server response
 *   console.log(res.err.info)
 * }
 *
 * ```
 */

export type ServiceConfigOptions = ClientConfig & {
  [Client in keyof ServiceHttpClients]?: ClientConfig;
};

export const create = (configs: ServiceConfigOptions = defaultConfig) => {
  const {backend, canyon, ...baseConfigs} = configs;
  const clients: ServiceHttpClients = {
    backend: createClient({...baseConfigs, ...backend}),
    canyon: createClient({...baseConfigs, ...canyon}),
  };

  const setBearerToken = (jwt: string) => {
    for (const client in clients) {
      if (clients.hasOwnProperty(client)) {
        clients[client].defaults.headers = {
          ...clients[client].defaults.headers,
          ['Authorization']: `Bearer: ${jwt}`,
        };
      }
    }
  };

  return {
    clients,
    setBearerToken,
    createCancelController,
    ...setupEndpoints(clients),
  };
};
