import { ServiceHttpClients } from '../../types';
import { setupUserEndpoints } from './user-endpoints';
import { setupActivityEndpoints } from './activity-endpoints';
import { setupPlatformEndpoints } from './platform-endpoints';
import { setupWorkoutEndpoints } from './workout-endpoints';
import { setupAuthEndpoints } from './auth-endpoints';
import { setupCoachEndpoints } from './coaches-endpoints';
import { setupCheckoutEndpoints } from './checkout-endpoints';
import { setupOrderEndpoints } from './order-endpoints';
import { setupFeaturesEndpoints } from './features-endpoints';
import { setupRegionEndpoints } from './region-endpoints';
import { setupEmailEndpoints } from './email-endpoints';

export const setupOpenEndpoints = (clients: ServiceHttpClients) => {
  const endpoints = {
    user: setupUserEndpoints(clients),
    activity: setupActivityEndpoints(clients.backend),
    platform: setupPlatformEndpoints(clients.backend),
    workout: setupWorkoutEndpoints(clients.backend),
    auth: setupAuthEndpoints(clients.backend),
    coach: setupCoachEndpoints(clients.backend),
    checkout: setupCheckoutEndpoints(clients.canyon),
    order: setupOrderEndpoints(clients.canyon),
    feature: setupFeaturesEndpoints(clients.backend),
    region: setupRegionEndpoints(clients.backend),
    email: setupEmailEndpoints(clients.canyon)
  };

  return endpoints;
};
