import { typedObjectKeys } from '../objects';

/**
 * Transform an object with key/value pairs to an equivalent
 * URL param string. Does not include the query start character: `?`.
 *
 * Only maps booleans, numbers, and other truthy values
 * @param params
 */
export const toURLParams = (params: object): string => {
  let urlParams: string = '';

  const keys = typedObjectKeys(params).filter(key => {
    return (
      !!params[key] ||
      typeof params[key] === 'number' ||
      typeof params[key] === 'boolean'
    );
  });

  keys.forEach((key, index) => {
    const value = params[key];

    if (!value && typeof value !== 'number' && typeof value !== 'boolean') {
      return;
    }

    urlParams += `${key}=${encodeURIComponent(value)}`;

    if (index !== keys.length - 1) {
      urlParams += '&';
    }
  });

  return urlParams;
};
