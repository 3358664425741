import Image, {ImageProps} from 'next/image';
import styled from 'styled-components';

const BackgroundWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
`;

interface BackgroundImageProps {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  imageProps?: ImageProps;
}

export const BackgroundNextImage: React.FC<BackgroundImageProps> = ({
  containerProps,
  imageProps,
}) => {
  // the ImageProps assertion is due to a current typing issue
  // with next and styled-components
  // see more: https://github.com/vercel/next.js/issues/19764
  const allImageProps = {
    layout: 'fill',
    objectFit: 'cover',
    quality: 100,
    ...imageProps,
  } as ImageProps;

  return (
    <BackgroundWrap {...containerProps}>
      <Image {...allImageProps} />
    </BackgroundWrap>
  );
};
