import * as React from 'react';

import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {TestimonialCard} from 'src/features/contentful/components';
import {ITestimonials} from 'src/utils/contentful';

const HorizontalTestimonialsContainer = styled.div`
  display: flex;
  overflow-x: auto;

  padding: 3.75rem 3rem 2rem 4rem;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    padding: 3.75rem 6rem 4rem 6rem;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  > * {
    margin: 0 20px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

type HorizontalTestimonials = {
  entry: ITestimonials;
};

export const HorizontalTestimonials: React.FC<HorizontalTestimonials> = (
  props
) => {
  const {
    entry: {
      fields: {components},
    },
  } = props;
  if (!components) return null;

  return (
    <HorizontalTestimonialsContainer>
      {components.map((item, index) => (
        <TestimonialCard key={index} testimonial={item} />
      ))}
    </HorizontalTestimonialsContainer>
  );
};
