import { HttpClient, ClientConfig } from '../../http';

export const setupRegionEndpoints = (client: HttpClient) => {
  return {
    /**
     * Get a user's pricing region from their country.
     * If no country is provided, IP address is used to determine the country / region
     * The returned region can be used as the region for checkout sessions and filtering pricing information
     * @param countryCode - an ISO 3166-1 alpha-2 country code
     */
    getPricingRegion: (countryCode?: string, config?: ClientConfig) => {
      const query = countryCode ? `?country=${countryCode}` : '';
      const url = `/b/region` + query;
      return client.get<string, string>(url, config);
    }
  };
};
