import {useEffect, useState, useMemo} from 'react';

import {useIntl} from 'react-intl';
import styled from 'styled-components';

import {create} from '@stryd/api-client';
import {FeaturesGroup} from '@stryd/models';
import {MembershipFeatureGroupList, colors} from '@stryd/react-ui';

import {usePageRegionData} from 'src/contexts/PageRegionDataContext';
import {IMembershipFeaturesTableFields} from 'src/utils/contentful';

import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const MembershipFeaturesTableWrapper = styled(FullWidthSection)`
  background-color: ${colors.themes.dark.backgroundLayers[0]};
  flex-direction: column;
`;

const MembershipFeaturesTableTitle = styled.h2`
  color: ${colors.themes.dark.headerText};
  margin-bottom: 50px;
`;

const ErrorMessage = styled.span`
  color: ${colors.themes.dark.text};
`;

type MembershipFeaturesTableProps =
  ContentfulTemplateComponentProps<IMembershipFeaturesTableFields>;

const tableStyle: React.CSSProperties = {
  marginBottom: '30px',
};

export const MembershipFeaturesTable: React.FC<MembershipFeaturesTableProps> = (
  props
) => {
  const {entry} = props;

  const intl = useIntl();
  const {locale} = usePageRegionData();

  const apiClient = useMemo(
    () =>
      create({
        baseURL: process.env.NEXT_PUBLIC_STRYD_API_BASEURL,
      }),
    []
  );

  const [featureGroups, setFeatureGroups] = useState<FeaturesGroup[] | null>(
    null
  );

  useEffect(() => {
    const getFeatureGroups = async (): Promise<void> => {
      const res = await apiClient.feature.getFeatures({locale});
      if (res.ok) {
        setFeatureGroups(res.data);
      } else {
        console.log('failed to get data for membership features table');
      }
    };

    getFeatureGroups();
  }, [apiClient.feature, locale]);

  const {tableTitle} = entry.fields;

  return (
    <MembershipFeaturesTableWrapper id={'membership-features-table'}>
      <MembershipFeaturesTableTitle>{tableTitle}</MembershipFeaturesTableTitle>
      {featureGroups ? (
        <MembershipFeatureGroupList
          featureGroups={featureGroups}
          tableStyle={tableStyle}
          dataOnlyHeaderText={intl.formatMessage({
            id: 'data.featureAccess.title',
            defaultMessage: 'Data Only',
          })}
          membershipHeaderText={intl.formatMessage({
            id: 'full.featureAccess.title',
            defaultMessage: 'Membership',
          })}
        />
      ) : (
        <ErrorMessage>
          Sorry, there was a problem getting the list of features. Please
          refresh the page or try again later.
        </ErrorMessage>
      )}
    </MembershipFeaturesTableWrapper>
  );
};
