import React from 'react';

import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {
  RichText,
  FluidContainer,
  ContentContainer,
} from 'src/features/contentful/components';
import {useMediaQuery} from 'src/hooks/useMediaQuery';
import {colors as customColors} from 'src/styles';
import {IHeroSectionFields} from 'src/utils/contentful/content-models';
import {
  getButtonColor,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
  getImageHeightFromAsset,
} from 'src/utils/contentful/content-models/util';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {CtaButton} from '../../CtaButton';
import {ContentfulTemplateComponentProps} from '../types';

const {tabletPortrait, tabletLandscape} = dimensions.breakpoints;

const HeroSectionContainer = styled(FluidContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 1rem 6rem;
  max-width: auto;
  padding: 4rem;

  @media screen and (min-width: ${tabletLandscape}) {
    padding: 100px 140px;
    min-height: calc(100vh - ${dimensions.topNavHeight});
  }
`;

const RichTextContainer = styled.div`
  h1 {
    font-size: 3.75rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
  }

  p:first-child {
    margin: 0;
  }

  @media screen and (min-width: ${tabletLandscape}) {
    max-width: 40%;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-top: 3rem;

  > * + * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${tabletPortrait}) {
    width: 100%;
  }
`;

const getFontColor = (
  background: keyof typeof customColors['sectionBackground']
) => {
  switch (background) {
    case 'dark':
    case 'medium_dark':
      return 'white';
    case 'light':
    case 'medium_light':
      return 'inherit';
    default:
      return 'inherit';
  }
};

type HomeHeroProps = ContentfulTemplateComponentProps<IHeroSectionFields>;

export const HomeHero: React.FC<HomeHeroProps> = (props) => {
  const {
    entry: {
      fields: {
        textContent,
        background = 'light',
        landscapeBackgroundImage,
        portraitBackgroundImage,
        ctas,
        css,
      },
    },
  } = props;

  const isPortrait = useMediaQuery(`(max-width: ${tabletLandscape})`);

  const providedPortraitBackgroundImage =
    portraitBackgroundImage || landscapeBackgroundImage || null;

  const backgroundColor = customColors.sectionBackground[background];

  const ctaButtons = ctas
    ? ctas.map((cta) => {
        return (
          <CtaButton
            key={cta.sys.id}
            cta={cta}
            color={getButtonColor(cta.fields.pageLink)}
          />
        );
      })
    : null;

  return (
    <HeroSectionContainer
      style={{
        color: getFontColor(background),
      }}
    >
      {!isPortrait && providedPortraitBackgroundImage ? (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(providedPortraitBackgroundImage),
            alt: providedPortraitBackgroundImage.fields.description,
            priority: true,
          }}
          containerProps={{
            style: {
              backgroundColor,
            },
          }}
        />
      ) : null}
      <ContentContainer>
        <RichTextContainer>
          <RichText content={textContent} overrideCSS={css} />
        </RichTextContainer>
        {isPortrait && providedPortraitBackgroundImage ? (
          <Image
            src={getImageSourceFromAsset(providedPortraitBackgroundImage)}
            alt={providedPortraitBackgroundImage.fields.description}
            priority={true}
            width={getImageWidthFromAsset(providedPortraitBackgroundImage)}
            height={getImageHeightFromAsset(providedPortraitBackgroundImage)}
            layout="responsive"
          />
        ) : null}
        {ctas && <CTAContainer>{ctaButtons}</CTAContainer>}
      </ContentContainer>
    </HeroSectionContainer>
  );
};
