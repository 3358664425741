import {Entry} from 'contentful';
import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {BackgroundNextImage} from 'src/components/BackgroundNextImage';
import {
  getImageSourceFromAsset,
  getImageAltFromAsset,
  getFontColor,
} from 'src/utils/contentful';
import {
  IFeatureDescriptionFields,
  IShortFeatureDescriptionGroupFields,
} from 'src/utils/contentful/content-models/generated/contentful';

import {FullWidthSection} from '../../FullWidthSection';
import {ContentfulTemplateComponentProps} from '../types';

const FeatureDescriptionGrid = styled.div`
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
  justify-items: center;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const FeatureDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  text-align: center;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    margin-bottom: calc(4 * ${dimensions.defaultPadding});
  }

  max-width: 240px;
`;

const IconContainer = styled.div`
  margin-bottom: ${dimensions.defaultPadding};
`;

interface ShortFeatureDescriptionProps {
  featureDescriptionEntry: Entry<IFeatureDescriptionFields>;
}

export const FeatureDescription: React.FC<ShortFeatureDescriptionProps> = (
  props
) => {
  const {featureDescriptionEntry} = props;

  const {
    fields: {icon, heading, description},
  } = featureDescriptionEntry;

  return (
    <FeatureDescriptionContainer>
      {icon ? (
        <IconContainer>
          <Image
            src={getImageSourceFromAsset(icon)}
            alt={icon?.fields.title}
            height="72px"
            width="72px"
          />
        </IconContainer>
      ) : null}
      <h3>{heading}</h3>
      <p>{description}</p>
    </FeatureDescriptionContainer>
  );
};

type ShortFeatureDescriptionGroupProps =
  ContentfulTemplateComponentProps<IShortFeatureDescriptionGroupFields>;

export const ShortFeatureDescriptionGroup: React.FC<ShortFeatureDescriptionGroupProps> =
  (props) => {
    const {entry} = props;
    const {
      fields: {backgroundImage},
    } = entry;

    const fontColor = getFontColor('light', backgroundImage);

    return (
      <FullWidthSection style={{color: fontColor}}>
        {backgroundImage && (
          <BackgroundNextImage
            imageProps={{
              src: getImageSourceFromAsset(backgroundImage),
              alt: getImageAltFromAsset(backgroundImage),
            }}
          />
        )}
        <FeatureDescriptionGrid>
          {entry.fields.shortFeatureDescriptions.map((featureDescription) => (
            <FeatureDescription
              key={featureDescription.sys.id}
              featureDescriptionEntry={featureDescription}
            />
          ))}
        </FeatureDescriptionGrid>
      </FullWidthSection>
    );
  };
