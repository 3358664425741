import {HttpClient, ClientConfig} from '../../http';
import {
  RMA,
  QueryRMARequestOptions,
  RMAPostRequestOptions,
} from '@stryd/models';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';

const returnPath = `/canyon/admin/returns`;

export const setupReturnEndpoints = (client: HttpClient) => {
  return {
    getRMAById: (rmaId: string, config?: ClientConfig) => {
      return client.get<RMA, string>(`${returnPath}/${rmaId}`, config);
    },

    listRMAs: (
      query?: Partial<QueryRMARequestOptions>,
      config?: ClientConfig
    ) => {
      const queryString = query ? '?' + toURLParams(query) : '';
      return client.get<RMA[] | null, string>(returnPath + queryString, config);
    },

    createRMA: (params: RMAPostRequestOptions, config?: ClientConfig) => {
      return client.post<RMA, string>(returnPath, params, config);
    },
  };
};
