import React from 'react';

import {motion, useScroll, useTransform} from 'framer-motion';
import {FormattedMessage} from 'react-intl';

export const ScrollBackground2 = ({
  image,
}: {
  image: string;
}): React.ReactElement => {
  const {scrollY} = useScroll();
  const backgroundY = useTransform(scrollY, [0, 1000], [0, 0]);
  const textY = useTransform(scrollY, [0, 1000], [0, -200]);

  return (
    <div className="relative h-[200vh] lg:h-[250vh] overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${image})`,
          y: backgroundY,
        }}
      />

      <div className="relative z-10 h-full">
        <motion.div
          className="absolute top-1/4 right-1/4 transform translate-x-1/2 -translate-y-1/2 bg-black text-white p-8 max-w-2xl"
          style={{y: textY}}
        >
          <p className="text-orange-500 text-lg mb-2">
            <FormattedMessage
              id="scrollBackgroundTwo.highlight"
              defaultMessage="Stryd captures more"
            />
          </p>
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4 leading-tight">
            <FormattedMessage
              id="scrollBackgroundTwo.lineOne"
              defaultMessage="Running insights"
            />
            <br />
            <FormattedMessage
              id="scrollBackgroundTwo.lineTwo"
              defaultMessage="redefined"
            />
          </h1>
          <p className="text-gray-300 text-lg lg:text-md leading-relaxed">
            <FormattedMessage
              id="scrollBackgroundTwo.supporting"
              defaultMessage="Stryd helps you unlock your true potential, to achieve goals faster.
                    Precision power metrics utilize advanced sensor technology, custom
                    algorithms, and real-time environmental data to provide the most
                    accurate running analytics available. Along with training plans —
                    your personal records do not stand a chance against your newfound
                    capabilities."
            />
          </p>
        </motion.div>
      </div>
    </div>
  );
};
