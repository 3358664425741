import { cn } from "@/lib/utils";

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "grid auto-rows-min grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  className,
  title,
  description,
  header,
  icon,
}: {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "row-span-1 rounded-xl border-black bg-white border p-4 flex flex-col",
        className
      )}
    >
        <div className="flex flex-row mb-4">
          {header}
          <h4 className="font-sans font-bold text-neutral-600 dark:text-neutral-200 mb-2 mt-2 pl-4 text-lg">
            {title}
          </h4>
        </div>
        <p className="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300">
          {description}
        </p>
    </div>
  );
};
