import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import Image from 'next/image';
import styled from 'styled-components';

import {colors} from '@stryd/react-ui';

import {
  getImageAltFromAsset,
  getImageSourceFromAsset,
  ITestimonialFields,
} from 'src/utils/contentful';

type TestimonialProps = Pick<ITestimonialFields, 'name' | 'body' | 'image'>;

const TestimonialCard = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: inline-block;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: ${colors.themes.dark.foreground};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: ${colors.themes.dark.text};
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid ${colors.themes.dark.accentPrimary};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Body = styled.div`
  h4 {
    color: ${colors.themes.dark.headerText};
    font-size: 1.25rem;
  }
`;

export const Testimonial: React.FC<TestimonialProps> = (props) => {
  const {name, image, body} = props;

  return (
    <TestimonialCard>
      <Header>
        <ImageWrapper>
          <Image
            src={getImageSourceFromAsset(image)}
            alt={getImageAltFromAsset(image)}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </ImageWrapper>
        {name}
      </Header>
      <Body
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(body),
        }}
      />
    </TestimonialCard>
  );
};
