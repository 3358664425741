import {FootDataSession, IActivitySummary} from '@stryd/models';
import {HttpClient, ClientConfig} from '../../http';

const basePath = `/b/admin/users`;

export interface FootDataPoint {
  x: number;
  y: number;
  z: number;
}

export interface FootData {
  incline: number;
  positions: Array<FootDataPoint>;
  velocities: Array<FootDataPoint>;
  power: number;
  sample_cnt: number;
  sample_freq: number;
  scale_x: number;
  scale_y: number;
  scale_z: number;
  side: number;
  speed: number;
  timestamp: number;
}

export interface GetFootDataFileRequest {
  userId: string;
  id: number;
  includeContent?: boolean;
}

export interface GetFootDataFileResponse {
  created: number;
  created_time: string;
  deleted: false;
  file_path: string;
  file_url: string;
  firmware_version: string;
  id: number;
  pod_id: string;
  processing_status: string;
  updated: number;
  updated_time: string;
  upload_source: string;
  user_id: string;
  parsed_sessions: FootDataSession[] | null;
  related_activities: IActivitySummary[] | null;
}

export interface GetFootDataFilesRequest {
  userId: string;
  from: number;
  to: number;
  limit?: number;
}

export interface FootDataFile {
  user_id: string;
  created: number;
  created_time: string;
  updated: number;
  updated_time: string;
  id: number;
  file_path: string;
  upload_source: string;
  firmware_version: string;
  pod_id: string;
  deleted: false;
  processing_status: string;
}

export type GetFootDataFilesResponse = Array<FootDataFile>;

export interface FootpathVisualizationResponse {
  created: number;
  created_time: string;
  elapsed_time: number;
  file_path: string;
  foot_data_list: Array<FootData>;
  id: number;
  parsed_file_path: string;
  timestamp: number;
  updated: number;
  updated_time: string;
  user_id: string;
  upload_source: string;
  firmware_version: string;
}

export interface ReprocessFootDataRequest {
  userId: string;
  id: number;
  complete: boolean;
}

export interface ReprocessFootDataResponse {
  complete: boolean;
}

export const setupFootDataEndpoints = (client: HttpClient) => {
  return {
    getFootDataFiles: (
      {userId, from, to, limit}: GetFootDataFilesRequest,
      config?: ClientConfig
    ) => {
      return client.get<GetFootDataFilesResponse, {message: string}>(
        `${basePath}/${userId}/foot-data/files?from=${from}&to=${to}&limit=${limit}`,
        config
      );
    },
    getFileById: (
      {userId, id, includeContent = true}: GetFootDataFileRequest,
      config?: ClientConfig
    ) => {
      return client.get<GetFootDataFileResponse, string>(
        `${basePath}/${userId}/foot-data/files/${id}?include_content=${includeContent}`,
        config
      );
    },
    reprocessFootData: (
      {userId, id, complete}: ReprocessFootDataRequest,
      config?: ClientConfig
    ) => {
      return client.post<ReprocessFootDataResponse, {message: string}>(
        `${basePath}/${userId}/foot-data/files/${id}/reprocess`,
        {complete},
        config
      );
    },
  };
};
