import {Entry} from 'contentful';

import {IExperimentContainerFields, IWebPage} from 'src/utils/contentful';

import {Experiment, ExperimentVariation} from '../../experiment';
import {ContentfulTemplateComponentProps} from '../types';

type ExperimentContainerProps =
  ContentfulTemplateComponentProps<IExperimentContainerFields> & {
    pageEntry?: IWebPage;
  };
type VariationId = Extract<
  keyof IExperimentContainerFields,
  'variation1' | 'variation2' | 'control'
>;
type VariationIdRecord = {
  [K in VariationId]: K;
};

const Variation: VariationIdRecord = {
  control: 'control',
  variation1: 'variation1',
  variation2: 'variation2',
};

export const ExperimentContainer: React.FC<ExperimentContainerProps> = (
  props
) => {
  const {fields} = props.entry;
  const {contentfulTemplatePicker: ContentfulTemplatePicker} = props;

  const {
    experimentId,
    variation1Traffic,
    variation2Traffic,
    variation1,
    variation2,
    control,
  } = fields;

  const controlTraffic = 1 - variation1Traffic - (variation2Traffic ?? 0);

  const renderWithTemplatePicker = (variation: Entry<unknown>[]) => {
    return variation.map((entry, index) => {
      return (
        <ContentfulTemplatePicker
          key={entry.sys.id}
          entry={entry}
          entryIndex={index}
          entries={variation}
          pageEntry={props.pageEntry}
        />
      );
    });
  };

  return (
    <Experiment id={experimentId}>
      <ExperimentVariation
        variationId={Variation.control}
        weight={controlTraffic}
      >
        {renderWithTemplatePicker(control)}
      </ExperimentVariation>

      <ExperimentVariation
        variationId={Variation.variation1}
        weight={variation1Traffic}
      >
        {renderWithTemplatePicker(variation1)}
      </ExperimentVariation>

      {variation2 && variation2Traffic && (
        <ExperimentVariation
          variationId={Variation.variation2}
          weight={variation2Traffic}
        >
          {renderWithTemplatePicker(variation2)}
        </ExperimentVariation>
      )}
    </Experiment>
  );
};
