import * as React from 'react';

import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {CtaButton} from 'src/components/CtaButton';
import {ContentfulTemplateComponentProps} from 'src/components/contentful/types';
import {FluidContainer} from 'src/features/contentful/components';
import {RichText} from 'src/features/contentful/components';
import {HorizontalTestimonials} from 'src/features/contentful/components/HorizontalTestimonials';
import {
  getButtonColor,
  IHorizontalScrollSectionFields,
} from 'src/utils/contentful';

const HorizontalScrollSectionContainer = styled(FluidContainer)`
  text-align: center;
  line-height: 1.35;
  padding: ${dimensions.twicePadding} 1rem;
  box-shadow: inset 0 -20px 20px -10px #f5f5f5;

  @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
    padding: ${dimensions.twicePadding} 1rem;
    padding: 6rem 0 4rem 0;
  }
`;

const RichTextContainer = styled.div`
  h2 {
    font-size: 3.75rem;
    max-width: 900px;
    @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
      margin-bottom: 2.25rem;
    }
  }
  p {
    font-size: 1.5rem;
    max-width: 790px;
    color: #727478;

    @media screen and (min-width: ${dimensions.breakpoints.mobile}) {
      margin-bottom: ${dimensions.twicePadding};
    }
  }
  h2,
  p {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
  margin-top: ${dimensions.twicePadding};

  > * + * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    width: 100%;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    flex-flow: column nowrap;
    align-items: center;
    > * + * {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }
`;

type HorizontalScrollSectionProps =
  ContentfulTemplateComponentProps<IHorizontalScrollSectionFields>;

const horizontalScrollSectionComponents = {
  testimonials: HorizontalTestimonials,
};

export const HorizontalScrollSection: React.FC<HorizontalScrollSectionProps> = (
  props
) => {
  const {
    entry: {
      fields: {textContent, components, callsToAction},
    },
  } = props;

  const ctaButtons = callsToAction
    ? callsToAction.map((cta) => {
        return (
          <CtaButton
            key={cta.sys.id}
            cta={cta}
            color={getButtonColor(cta.fields.pageLink)}
          />
        );
      })
    : null;

  return (
    <HorizontalScrollSectionContainer>
      {textContent && (
        <RichTextContainer>
          <RichText content={textContent} />
        </RichTextContainer>
      )}

      {components &&
        components.map((component, index) => {
          const Component =
            horizontalScrollSectionComponents[component.sys.contentType.sys.id];

          return <Component key={index} entry={component} />;
        })}

      {ctaButtons && <CTAContainer>{ctaButtons}</CTAContainer>}
    </HorizontalScrollSectionContainer>
  );
};
