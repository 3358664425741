import {useState} from 'react';

import Image from 'next/image';
import {useRouter} from 'next/router';
import {range} from 'ramda';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

import {dimensions, BorderOnlyActionButton} from '@stryd/react-ui';
import {stripeAmountToCurrencyAmount} from '@stryd/util-lib';

import {regionConfigs} from 'src/config';
import {usePageRegionData} from 'src/contexts';
import {useCart} from 'src/features/cart/contexts';
import {CartActionItem} from 'src/features/cart/types';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {colors as customColors} from 'src/styles';
import {dimensions as customDimensions} from 'src/styles';
import {addToCartEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';
import {variantToItems} from 'src/utils/analytics/variantToItems';
import {IFeaturedHeroProductFields} from 'src/utils/contentful/content-models/generated/contentful';
import {getImageSourceFromAsset} from 'src/utils/contentful/content-models/util';

import {BackgroundNextImage} from '../../BackgroundNextImage';
import {MarketingSectionHeader} from '../../MarketingSectionHeader';
import {ProductVariantPrice} from '../../ProductVariantPrice';
import {ContentfulTemplateComponentProps} from '../types';

const SectionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;

  position: relative;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    min-height: 65vh;
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    justify-content: center;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    padding-left: ${customDimensions.defaultPageSidePaddingMobile};
    padding-right: ${customDimensions.defaultPageSidePaddingMobile};
    background-image: linear-gradient(to bottom, #545659, gainsboro);
  }
`;

const ProductContainer = styled.div`
  background: white;
  width: 550px;
  padding: 75px 25px;
  min-width: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  > * + * {
    margin-top: calc(1.5 * ${dimensions.defaultPadding});
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    width: 90%;
    min-width: unset;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    width: 100%;
    min-width: unset;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const ProductTitle = styled.h1`
  font-size: 72px;
  line-height: 72px;
  text-transform: uppercase;
  margin-bottom: 0;
`;

const ProductSubtitle = styled.p`
  font-size: 21px;
  line-height: 21px;
  color: ${customColors.textLight};
  margin-bottom: 0;
`;

const PriceContainer = styled.div`
  text-align: center;
`;

const Price = styled.p`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0;
`;

const ShippingLabel = styled.p`
  font-size: 0.75rem;
  color: ${customColors.textLight};
`;

const AddToCartContainer = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${dimensions.defaultPadding};
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid ${customColors.textLight};
  border-radius: 8px;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  align-items: center;
  position: relative;

  select {
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 80px;
    padding: ${dimensions.defaultPadding};

    &:focus {
      outline: none;
    }
  }

  &:hover {
    border-color: #666;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px rgb(0 123 255 / 50%);
  }
`;

const SelectArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${dimensions.defaultPadding};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;

  &:after {
    content: '';
    width: 0.7em;
    height: 0.4em;
    background-color: ${customColors.textLight};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    display: block;
  }
`;

const FootnoteContainer = styled.div`
  font-size: 0.7rem;
  text-align: center;
  max-width: 50%;
`;

const FootnoteHeader = styled.p`
  color: red;
  margin-bottom: 5px;
`;

const DescriptionContainer = styled.div`
  flex: 1;
  padding: ${dimensions.twicePadding};

  @media screen and (max-width: ${dimensions.breakpoints.desktopSmall}) {
    display: none;
  }
`;

const BackgroundImageWrapper = styled.div`
  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    display: none;
  }
`;

type HeroProductSectionProps =
  ContentfulTemplateComponentProps<IFeaturedHeroProductFields>;

export const HeroProductSection: React.FC<HeroProductSectionProps> = (
  props
) => {
  const {entry} = props;
  const {
    fields: {
      product,
      productVariant,
      productTitle,
      productSubtitle,
      includesShipping,
      featureProductImage,
      footnoteHeading,
      footnoteBody,
      marketingMessage,
      backgroundImage,
    },
  } = entry;
  const {region, locale} = usePageRegionData();
  const [, cartDispatch] = useCart();
  const [qtyInputValue, setQtyInputValue] = useState(1);
  const router = useRouter();

  const {currency} = regionConfigs[region];

  const pricing = getVariantPricing({
    variant: productVariant,
    region,
    currency,
  });

  const addToCart = () => {
    const item: CartActionItem = {
      product,
      selectedVariant: productVariant,
    };

    track(
      addToCartEvent({
        ecommerce: {
          currency: pricing.oneTimePricesTotal.currency,
          value: stripeAmountToCurrencyAmount(
            pricing.oneTimePricesTotal.unit_amount * qtyInputValue,
            pricing.oneTimePricesTotal.currency
          ),
          items: variantToItems(productVariant),
        },
      })
    );

    cartDispatch({
      type: 'ADD_QTY_TO_CART',
      item,
      region,
      quantity: qtyInputValue,
    });

    router.push({
      pathname: '/[region]/[locale]/cart',
      query: {region, locale},
    });
  };

  const handleQtyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target;
    const valueInt = parseInt(value, 10);
    setQtyInputValue(valueInt);
  };

  const qtySelectorOptions = range(1, 11); // range is exclusive on right

  return (
    <SectionContainer>
      {backgroundImage && (
        <BackgroundImageWrapper>
          <BackgroundNextImage
            imageProps={{
              src: getImageSourceFromAsset(backgroundImage),
              alt: backgroundImage.fields.description,
            }}
          />
        </BackgroundImageWrapper>
      )}
      <ProductContainer>
        <TitleContainer>
          <ProductTitle>{productTitle}</ProductTitle>
          <ProductSubtitle>{productSubtitle}</ProductSubtitle>
        </TitleContainer>

        <PriceContainer>
          <Price>
            <ProductVariantPrice variant={productVariant} />
          </Price>
          {includesShipping && (
            <ShippingLabel>
              <FormattedMessage
                id="product.includesShipping"
                defaultMessage="Includes Shipping"
              />
            </ShippingLabel>
          )}
        </PriceContainer>

        <div>
          <Image
            src={getImageSourceFromAsset(featureProductImage)}
            alt={
              featureProductImage.fields.description ||
              featureProductImage.fields.title
            }
            width={'175px'}
            height={'206px'}
          />
        </div>

        <AddToCartContainer>
          <SelectWrapper>
            <select
              aria-label="Quantity"
              value={qtyInputValue.toString()}
              onChange={handleQtyChange}
            >
              {qtySelectorOptions.map((qty) => (
                <option key={qty.toString()} value={qty.toString()}>
                  {qty}
                </option>
              ))}
            </select>
            <SelectArrowWrapper />
          </SelectWrapper>

          <BorderOnlyActionButton onClick={addToCart}>
            <FormattedMessage
              id="actions.addToCart"
              defaultMessage="Add to Cart"
            />
          </BorderOnlyActionButton>
        </AddToCartContainer>

        <FootnoteContainer>
          <FootnoteHeader>{footnoteHeading}</FootnoteHeader>
          <p>{footnoteBody}</p>
        </FootnoteContainer>
      </ProductContainer>

      <DescriptionContainer>
        <MarketingSectionHeader style={{color: 'white'}}>
          {marketingMessage}
        </MarketingSectionHeader>
      </DescriptionContainer>
    </SectionContainer>
  );
};
