import {
  StripeOrder,
  StripeOrderQueryOptions,
  StripeOrderUpdateOptions,
  Refund,
  StripeOrderRefund,
  UNSAFE_STRIPE,
} from '@stryd/models';
import {ClientConfig, HttpClient} from '../../http';
import {toURLParams} from '@stryd/util-lib/urls/toURLParams';
import {DeepPartial} from '../../../types';
import {ResendEmailQueryOptions} from '@stryd/models';

const orderPath = `/canyon/admin/orders`;

export const setupOrderEndpoints = (client: HttpClient) => {
  return {
    getAll: (
      query?: Partial<StripeOrderQueryOptions>,
      config?: ClientConfig
    ) => {
      const queryString = query
        ? '?' + toURLParams({...query, format: 'purchase'})
        : '';
      return client.get<UNSAFE_STRIPE.StripePurchase[] | null, string>(
        orderPath + queryString,
        config
      );
    },

    getById: (id: string, config?: ClientConfig) => {
      const queryString = '?' + toURLParams({format: 'purchase'});
      return client.get<UNSAFE_STRIPE.StripePurchase, string>(
        `${orderPath}/${id}${queryString}`,
        config
      );
    },

    updateById: (
      id: string,
      updates: DeepPartial<UNSAFE_STRIPE.StripePurchaseUpdateOptions>,
      config?: ClientConfig
    ) => {
      return client.patch<UNSAFE_STRIPE.StripePurchase, string>(
        `${orderPath}/${id}?format=purchase`,
        updates,
        config
      );
    },

    getRefundsById: (id: string, config?: ClientConfig) => {
      return client.get<Refund[] | null>(`${orderPath}/${id}/refunds`, config);
    },

    createRefund: (
      id: string,
      data: StripeOrderRefund,
      config?: ClientConfig
    ) => {
      return client.post<Refund>(`${orderPath}/${id}/refunds`, data, config);
    },

    resendEmail: (query: ResendEmailQueryOptions, config?: ClientConfig) => {
      const {order_id, ...rest} = query;

      const queryString =
        Object.keys(rest).length > 0 ? '?' + toURLParams(rest) : '';
      return client.post(
        `${orderPath}/${order_id}/resend/email` + queryString,
        config
      );
    },
  };
};
