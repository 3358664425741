import React from 'react';

import Image from 'next/image';
import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {RichText} from 'src/features/contentful/components';
import {ContentContainer} from 'src/features/contentful/components';
import {
  findTopicChainIndex,
  getFontColor,
  getImageAltFromAsset,
  getImageHeightFromAsset,
  getBackgroundColor,
  getImageSourceFromAsset,
  getImageWidthFromAsset,
  IInDepthTopicSectionFields,
  ITopicSectionFields,
  getButtonColor,
} from 'src/utils/contentful';

import {BackgroundNextImage} from '../..';
import {CtaButton} from '../../CtaButton';
import {ContentfulTemplateComponentProps} from '../types';

const TopicContainer = styled(ContentContainer)<{direction?: 'row' | 'column'}>`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media screen and (min-width: ${dimensions.breakpoints.tabletPortrait}) {
    flex-direction: ${({direction}) =>
      direction === 'column' ? 'column' : 'row'};
    padding: 100px 140px;
  }
`;

const TopicContentContainer = styled.div<{direction?: 'row' | 'column'}>`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 636px;
  margin: 0 auto;

  @media screen and (min-width: ${dimensions.breakpoints.tabletPortrait}) {
    align-items: ${({direction}) =>
      direction === 'column' ? 'center' : 'flex-start'};
    text-align: ${({direction}) =>
      direction === 'column' ? 'center' : 'left'};
  }
`;

const TopicSectionTextContent = styled.div<{
  headerColor?: string;
  cssOverrides?: string;
}>`
  margin-bottom: 1.5rem;

  h2 {
    font-size: 3.75rem;
    color: ${({headerColor}) => headerColor};
    margin: 0;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 1.5rem;
    }
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${({cssOverrides}) => cssOverrides}
`;

const InDepthTopicTextContent = styled.div<{
  headerColor?: string;
  cssOverrides?: string;
}>`
  h2 {
    color: ${({headerColor}) => headerColor};
    font-size: 2.5rem;
    line-height: inherit;
    margin: 0;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 1.5rem;
    }
  }
  h3 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.25rem;
    }
  }

  ${({cssOverrides}) => cssOverrides}
`;

const ImageContainer = styled.div<{direction?: 'row' | 'column'}>`
  flex: 2;
  display: flex;
  justify-content: center;
  min-width: 100px;
  min-height: 100px;
  position: relative;
  max-width: 600px;
  max-height: 600px;
  margin: 0 auto;
  margin-top: ${({direction}) => (direction === 'column' ? '72px' : undefined)};

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
    width: 100%;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-top: 3rem;

  > * + * {
    margin-left: 1rem;
  }
`;

const TopicSectionWrapper = styled.div<{
  sectionIndex: number;
  direction?: 'row' | 'column';
}>`
  width: 100%;
  position: relative;

  & > ${TopicContainer} {
    flex-direction: ${({sectionIndex, direction}) =>
      direction === 'column'
        ? 'column'
        : sectionIndex % 2 == 0
        ? 'row'
        : 'row-reverse'};

    & > ${ImageContainer} {
      ${({sectionIndex, direction}) =>
        direction === 'column'
          ? undefined
          : sectionIndex % 2 == 0
          ? 'margin-left: 80px;'
          : 'margin-right: 80px;'}
    }
  }

  &:nth-of-type(n) > ${TopicContainer} {
    @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
      flex-direction: column;
      position: relative;

      & > ${ImageContainer} {
        margin: 50px 0;
      }
    }
    @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
      & > ${ImageContainer} {
        margin: 30px 0;
      }
    }
  }
`;

type IGeneralTopicsSectionFields =
  | ITopicSectionFields
  | IInDepthTopicSectionFields;

type GeneralTopicSectionProps =
  ContentfulTemplateComponentProps<IGeneralTopicsSectionFields>;

interface RichTextContainer {
  [index: string]:
    | typeof TopicSectionTextContent
    | typeof InDepthTopicTextContent
    | undefined;
}

const richTextContainers: RichTextContainer = {
  topicSection: TopicSectionTextContent,
  inDepthTopicSection: InDepthTopicTextContent,
};

export const GeneralTopicSection: React.FC<GeneralTopicSectionProps> = (
  props
) => {
  const {fields, sys} = props.entry;
  const {
    textContent,
    image,
    backgroundImage,
    background = 'light',
    ctas,
    cssOverrides,
    direction = 'row',
  } = fields;

  const backgroundColor = getBackgroundColor(background, backgroundImage);
  const fontColor = getFontColor(background, backgroundImage);

  const selfIndex = findTopicChainIndex(props.entries, sys.id);

  // const getHeaderFontColor = (selfIndex: number): string | undefined => {
  //   if (backgroundImage) {
  //     return customColors.backgroundImageText;
  //   }
  //   if (background === 'light') {
  //     return customColors.topicHeaders[
  //       selfIndex % customColors.topicHeaders.length
  //     ];
  //   }
  //   return fontColor;
  // };

  const ctaButtons = ctas
    ? ctas.map((cta) => (
        <CtaButton
          key={cta.sys.id}
          cta={cta}
          color={getButtonColor(cta.fields.pageLink)}
        />
      ))
    : null;

  const RichTextContent = richTextContainers[sys.contentType.sys.id];

  return (
    <TopicSectionWrapper
      data-testid={sys.id}
      data-entryid={sys.id}
      data-entrytype={sys.contentType.sys.id}
      style={{
        textAlign: 'left',
        backgroundColor,
        color: fontColor,
      }}
      sectionIndex={selfIndex}
      direction={direction}
    >
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      <TopicContainer
        data-testid={`topic-container-${sys.id}`}
        direction={direction}
      >
        <TopicContentContainer direction={direction}>
          {RichTextContent && (
            <RichTextContent cssOverrides={cssOverrides}>
              <RichText content={textContent} />
            </RichTextContent>
          )}
          {ctaButtons ? <CTAContainer>{ctaButtons}</CTAContainer> : null}
        </TopicContentContainer>
        {image && (
          <ImageContainer direction={direction}>
            <Image
              src={getImageSourceFromAsset(image)}
              alt={image.fields.title}
              width={getImageWidthFromAsset(image) || ''}
              height={getImageHeightFromAsset(image) || ''}
              objectFit={'contain'}
              objectPosition={'50 50'}
            />
          </ImageContainer>
        )}
      </TopicContainer>
    </TopicSectionWrapper>
  );
};
