import {
  PermissionGroupConfig,
  PermissionGroup,
  PermissionGroupUpdateOptions,
  IUser,
} from '@stryd/models';
import {DeepPartial} from '../../../types';
import {toURLParams} from '@stryd/util-lib';
import {ClientConfig, HttpClient} from '../../http';

const permissionGroupsPath = `/b/admin/permission-groups`;

export const setupPermissionGroupsEndpoints = (client: HttpClient) => {
  return {
    getAllPermissionGroups: (config?: ClientConfig) => {
      return client.get<PermissionGroup[] | null, string>(
        permissionGroupsPath,
        config
      );
    },

    createPermissionGroup: (
      data: PermissionGroupConfig,
      config?: ClientConfig
    ) => {
      return client.post<PermissionGroup, string>(
        permissionGroupsPath,
        data,
        config
      );
    },

    /**
     * @param groups - should be a comma delimited list of permission group names
     */
    getUsersByPermissionGroup: (groups: string, config?: ClientConfig) => {
      const queryString = toURLParams({groups});

      return client.get<IUser[] | null, string>(
        `${permissionGroupsPath}/search?${queryString}`,
        config
      );
    },

    deletePermissionGroup: (
      permissionGroupName: string,
      config?: ClientConfig
    ) => {
      return client.delete<string, string>(
        `${permissionGroupsPath}/${permissionGroupName}`,
        config
      );
    },

    getPermissionGroupByName: (
      permissionGroupName: string,
      config?: ClientConfig
    ) => {
      return client.get<PermissionGroup, string>(
        `${permissionGroupsPath}/${permissionGroupName}`,
        config
      );
    },

    updatePermissionGroup: (
      permissionGroupName: string,
      updates: DeepPartial<PermissionGroupUpdateOptions>,
      config?: ClientConfig
    ) => {
      return client.patch<PermissionGroup, string>(
        `${permissionGroupsPath}/${permissionGroupName}`,
        updates,
        config
      );
    },
  };
};
