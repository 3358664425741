import styled from 'styled-components';

import {dimensions} from '@stryd/react-ui';

import {dimensions as customDimensions} from 'src/styles';

export const BannerContainer = styled.div`
  background-image: linear-gradient(to left, #fbd75f, #f7701f);
  height: ${customDimensions.bannerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
`;

const TEXT_SPACING = '1rem';

export const BannerTextContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 0;
  margin-top: -${TEXT_SPACING};

  h3 {
    color: white;
    margin-left: ${TEXT_SPACING};
    margin-right: ${TEXT_SPACING};
    margin-top: ${TEXT_SPACING};
    margin-bottom: 0;

    @media screen and (max-width: ${dimensions.breakpoints.tabletPortrait}) {
      font-size: 16px;
    }
  }

  a {
    color: black;
  }
`;
