import React from 'react';

import {documentToHtmlString} from '@contentful/rich-text-html-renderer';

import {BackgroundNextImage} from 'src/components/BackgroundNextImage';
import {IBanner, IWebPage} from 'src/utils/contentful';
import {
  getImageAltFromAsset,
  getImageSourceFromAsset,
} from 'src/utils/contentful/content-models';

import {BannerContainer, BannerTextContent} from '../layout/Banner';
import {Layout, LayoutProps} from '../layout/Layout';

import {ContentfulTemplatePicker} from './ContentfulTemplatePicker';

export interface WebPageProps extends LayoutProps {
  pageEntry: IWebPage;
}

export const WebPage: React.FC<WebPageProps> = (props) => {
  const {pageContext, seo, preview, pageEntry, children} = props;

  const banners = pageEntry.fields.layout?.fields.banners?.map(
    (banner: IBanner, index: number) => {
      return (
        <BannerContainer key={`${banner.sys.id}-${index}`}>
          <BannerTextContent
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(banner.fields.textContent),
            }}
          />
        </BannerContainer>
      );
    }
  );

  const backgroundImage = pageEntry.fields.backgroundImage;

  return (
    <Layout
      pageContext={pageContext}
      seo={seo}
      preview={preview}
      banners={banners}
    >
      {children}
      {backgroundImage && (
        <BackgroundNextImage
          imageProps={{
            src: getImageSourceFromAsset(backgroundImage),
            alt: getImageAltFromAsset(backgroundImage),
          }}
        />
      )}
      {pageEntry.fields.components?.map((entry, index, entries) => {
        return (
          <ContentfulTemplatePicker
            key={`${entry.sys.id}-${index}`}
            entry={entry}
            entryIndex={index}
            entries={entries}
            pageEntry={pageEntry}
          />
        );
      })}
    </Layout>
  );
};
