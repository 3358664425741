import { ServiceHttpClients } from '../types';
import { setupOpenEndpoints } from './open';
import { setupAdminEndpoints } from './admin';

export const setupEndpoints = (clients: ServiceHttpClients) => {
  return {
    ...setupOpenEndpoints(clients),
    admin: setupAdminEndpoints(clients)
  };
};
