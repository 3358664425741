import {
  UserCalendarObjectCategory,
  UserCalendarObjectCode,
} from '@stryd/models';

type CalendarTypeMap = {
  [P in UserCalendarObjectCategory]: UserCalendarObjectCode;
};

// eslint-disable-next-line
// @ts-ignore
const calendarTypeMap: CalendarTypeMap = {
  activities: 'ua',
  events: 'ue',
  workouts: 'uw',
  plans: 'up',
};

type CalendarTypeMapFn = (
  type: UserCalendarObjectCategory
) => UserCalendarObjectCode;

export const calendarTypeToCode: CalendarTypeMapFn = (type) => {
  return calendarTypeMap[type];
};
